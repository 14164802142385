import moment from 'moment';

export function getLastTimestamps(range: number): number[] {
  const timestamps: number[] = []
  const today = new Date()

  for (let i = 0; i < range; i++) {
    const date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000)
    const timestamp = Math.floor(date.getTime() / 1000)
    timestamps.push(timestamp)
  }

  return timestamps.reverse()
}

interface TimestampObject {
  [key: string]: 0 | 1 |null// key is a timestamp and value is either 0 or 1
}

export function compareTimestamps(array: number[], object: TimestampObject): TimestampObject {
  const result: TimestampObject = {}
  if (!!!array.length) {
    return object
  }
  array.forEach((timestamp, index: number) => {
    Object.keys(object).forEach((key,index2) => {
        // if (index2==0) {
        //     result[key]= object[key as keyof TimestampObject]
        // }
        
      if (timestamp >= +key) {
        result[String(timestamp) as keyof TimestampObject] = object[key as keyof TimestampObject]
      }else{
        if (!(timestamp in result)) {
          
        result[String(timestamp) as keyof TimestampObject] = 0
      }

      }
       

    })
  })
  return {...result,...object}
}

export function generateTimestampsThisMonth(): number[] {
    const timestamps: number[] = [];
    const now: Date = new Date();
    const year: number = now.getFullYear();
    const month: number = now.getMonth();
  
    const startOfMonth: Date = new Date(year, month, 1);
    const endOfMonth: Date = new Date(year, month + 1, 0);
  
    let current: Date = startOfMonth;
    while (current <= endOfMonth) {
      timestamps.push(current.getTime());
      current = new Date(current.getTime() + 24 * 60 * 60 * 1000); // add 1 day in milliseconds
    }
  
    return timestamps.reverse();
  }

  export function generateTimestampsForCurrentMonth(): number[] {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
    const timestamps: number[] = [];
    let currentTimestamp = firstDayOfMonth.getTime()/1000;
  
    while (currentTimestamp <= lastDayOfMonth.getTime()/1000) {
      timestamps.push(currentTimestamp);
      currentTimestamp += 24 * 60 * 60 ; // add one day in milliseconds
      
    }
  
    return timestamps;
  }
  
  

  export function generateTimestampsThisWeek(): number[] {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDayOfMonth = currentDate.getDate();
    const firstDayOfWeek = new Date(currentYear, currentMonth, currentDayOfMonth - currentDayOfWeek);
    const timestampsThisWeek: number[] = [];
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentYear, currentMonth, firstDayOfWeek.getDate() + i);
      const timestamp = date.getTime();
      timestampsThisWeek.push(timestamp);
    }
  
    return timestampsThisWeek;
  }
  

//1676191452
// Output: {1627846301000: 0, 1627846302000: 1, 1627846303000: 0}

export function generateTimestampsForCurrentWeek(): number[] {
  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDayOfWeek);
  const timestamps: number[] = [];

  for (let i = 0; i < 7; i++) {
    const timestampInSeconds = Math.floor(new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i).getTime() / 1000);
    timestamps.push(timestampInSeconds);
  }

  return timestamps;
  }
  

export function getLastMonthTimestamps(): number[] {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() - 1; // subtract 1 to get last month
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);
    const timestamps = [];
    for (let date = startOfMonth; date <= endOfMonth; date.setDate(date.getDate() + 1)) {
      timestamps.push(date.getTime()/1000);
    }
    return timestamps;
  }

  
  export function getLastWeekTimestamps(): number[] {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const startOfLastWeek = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - lastWeek.getDay());
    const endOfLastWeek = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), startOfLastWeek.getDate() + 6);
  
    const timestamps: number[] = [];
  
    for (let i = startOfLastWeek.getTime(); i <= endOfLastWeek.getTime(); i += 86400000) {
      timestamps.push(i/1000);
    }
  
    return timestamps;
  }
  


export function convertToDateFormat(dateString: string): string {
  const dateMoment = moment(dateString, moment.ISO_8601, true);

  if (dateMoment.isValid()) {
    return dateMoment.format('YYYY-MM-DD');
  }

  return '';
}


export function calculateTimeDifference(timestamp1: number, timestamp2: number): string | null {
  if (isNaN(timestamp1) || isNaN(timestamp2)) {
   
    return null;
  }

  const differenceInSeconds = Math.abs(timestamp2 - timestamp1);



  const days = Math.floor(differenceInSeconds / (24 * 60 * 60));
  const hours = Math.floor((differenceInSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
  const seconds = differenceInSeconds % 60;

  let result = '';
  if (days > 0) {
    result += `${days} day${days !== 1 ? 's' : ''} `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours !== 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
  }
  if (seconds > 0 || result === '') { // Include seconds if result is empty to handle case where difference is less than a minute
    result += `${seconds} second${seconds !== 1 ? 's' : ''} `;
  }

  return result.trim();
}

export function calculateTimestampDifference(timestamp1: number, timestamp2: number,withDay:boolean=true): string {
  let differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);

  const timeUnits = [
   
    { unit: 'hour', duration: 1000 * 60 * 60 },
    { unit: 'minute', duration: 1000 * 60 },
    { unit: 'second', duration: 1000 },
  ];

  if (withDay) {
    timeUnits.unshift( { unit: 'day', duration: 1000 * 60 * 60 * 24 })
  }

  let difference = '';

  for (const unit of timeUnits) {
    const unitValue = Math.floor(differenceInMilliseconds / unit.duration);
    differenceInMilliseconds -= unitValue * unit.duration;

    if (unitValue > 0) {
      difference += `${unitValue} ${unit.unit}${unitValue > 1 ? 's' : ''}, `;
    }
  }

  return difference.slice(0, -2);
}

export  function calculatePercentageOfDay(duration: number,total:number): number {
  // const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const percentage = (duration / total) * 100;

  return +percentage.toFixed(3);
}

export function calculatePercentageOfMonth(duration: number,total:number): number {
  // const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // Approximate number of milliseconds in a month

  const percentage = (duration / total) * 100;

  return +percentage.toFixed(3);
}

export function calculatePercentageOfHour(duration: number,total:number): number {
  // const oneHourInMilliseconds = 60 * 60 * 1000; // Number of milliseconds in an hour

  const percentage = (duration / total) * 100;

  return +percentage.toFixed(2);
}

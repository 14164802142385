import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = '/invoices'

const OrderChargeStripe = (body) => {
  return Http.get(
    `${url}/${body.id}/charge/stripe?amount=${body.amount}&currency=${body.currency}&creditcard_id=${body.creditcard_id}`,
    body
  )
}

const ChargeStripe = () => {
  return useMutation(OrderChargeStripe)
}

export default ChargeStripe

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {UserModel} from '../auth/models/UserModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {IUpdateEmail} from './setting/SettingsModel'
import {Profile} from '../../../services/Profile'
import * as auth from '../auth/redux/AuthRedux'
// import { ToastContainer } from "react-toastify";
import {notify} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import { addToIgnore } from '../../store/commonAction'

const emailConfirmationSchema = Yup.lazy(() =>
  Yup.object().shape({
    newEmail: Yup.string().email().required(),
  })
)

interface Props {
  alertTextId?: string
  name?: string
}

const ResendEmailConfirmation: FC<Props> = ({alertTextId, name}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const state: any = useSelector<RootState>(
    ({commonReducer}) => commonReducer,
    shallowEqual
  ) as Object
  const [editLoading, setEditLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const addTOIgnoreListHand = () => {
    dispatch(addToIgnore(name??''))
  }

  const formik = useFormik<IUpdateEmail>({
    initialValues: {newEmail: user.email},
    validationSchema: emailConfirmationSchema,
    onSubmit: (values: any) => {
      setEditLoading(true)

      const params = {email: values.newEmail}

      Profile.partialUpdate(params)
        .then((response) => {
          setEditLoading(false)

          //Update authenticated user's email in all places
          dispatch(auth.actions.setUser({...user, ...params}))

          notify({
            type: 'success',
            message: intl.formatMessage({id: 'NOTIFICATIONS.EMAIL_CHANGED'}),
          })
        })
        .catch(({data}) => {
          setEditLoading(false)

          let messages = []

          for (let i in data) {
            messages.push(data[i])
          }

          notify({type: 'error', message: messages.join('\n')})
        })
    },
  })

  useEffect(() => {
    const validation_messages = Object(intl?.messages?.VALIDATORS)
    Yup.setLocale({...validation_messages})
  }, [])

  const sendEmailConfirmationLink = () => {
    setResendLoading(true)

    Profile.sendEmailConfirmationLink()
      .then((response) => {
        setResendLoading(false)

        notify({
          type: 'success',
          message: intl.formatMessage(
            {id: 'NOTIFICATIONS.EMAIL_CONFIRMATION_SENT'},
            {email: user.email}
          ),
        })

        //close modal
        handleClose()
      })
      .catch((err: any) => {
        setResendLoading(false)

        const error = err.response && (err.response || err.message)
        let data = error?.data
        console.error('data error ', data)
        if (!data) {
          return notify({
            type: 'error',
            message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
          })
        }
        let messages = []

        for (let i in data) {
          messages.push(
            <>
              {data[i]}
              <br />
            </>
          )
        }
        notify({type: 'error', message: <>{messages}</>})
      })
  }

  return (
    <>
      {(!user.email_confirmed&&!(state?.ignoreList.includes(name))) && (
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-3 my-3'>
          <span className='fas fa-exclamation-triangle fs-3 text-warning me-3' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <div className='fs-6 '>
                <FormattedMessage id={alertTextId ?? 'LABELS.EMAIL'} />
                <span
                  className='link-primary fw-bold mx-2'
                  onClick={handleShow}
                  style={{cursor: 'pointer'}}
                >
                  <FormattedMessage id='ACTIONS.RESEND' />
                </span>
                {/* <span
                  className='text-warning fw-bold ms-2'
                   onClick={addTOIgnoreListHand}
                  style={{cursor: 'pointer'}}
                >
                  <FormattedMessage id='IGNORE' />
                </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose} style={{zIndex: 100000}} backdrop='static'>
        <Modal.Header closeButton>
          <h1 className='mb-0 fs-3'>
            <FormattedMessage id='MODALS.EMAIL_CONFIRMATION.TITLE' />
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-13 text-center'>
            <div className='text-muted fw-bold fs-5'>
              <FormattedMessage id='MODALS.EMAIL_CONFIRMATION.DESCRIPTION' />
            </div>
          </div>

          <div className='d-flex flex-column'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='row'>
                <div className='col-lg-9'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder={intl.formatMessage({id: 'LABELS.EMAIL'})}
                    {...formik.getFieldProps('newEmail')}
                  />
                  {formik.touched.newEmail && formik.errors.newEmail && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.newEmail}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-3'>
                  <button type='submit' className='btn btn-success w-100' disabled={editLoading}>
                    {!editLoading && <FormattedMessage id='ACTIONS.EDIT' />}
                    {editLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {/* <FormattedMessage id="ACTIONS.WAIT" />{" "} */}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className='d-flex justify-content-end pt-12'>
            <button type='reset' className='btn btn-light me-3' onClick={handleClose}>
              <FormattedMessage id='ACTIONS.CANCEL' />
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              disabled={resendLoading}
              onClick={sendEmailConfirmationLink}
            >
              {!resendLoading && <FormattedMessage id='ACTIONS.RESEND' />}
              {resendLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <FormattedMessage id='ACTIONS.WAIT' />{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
  
    </>
  )
}

export {ResendEmailConfirmation}


import {loadTableParams} from '../helpers'
import Http, { _axios } from '../app/store/core/inceptor'
import {IReserved} from './Queries/billing/types'
import { useMutation, useQueryClient } from 'react-query'



export const Billing = {
  getLogs: (query: any) => {
   
    return _axios.get(`/billing/logs`, {
      params: {
        ...(!!query.page && {page:query.page}),
        ...(!!query.per_page && {per_page:query.per_page}),
      },
    })
  },
}

export const getReservedService = () =>
  Http.get<IReserved>('/services/main/reserved').then((res) => res.data)

export const UpdatePlan = (code: string) =>
  Http.post('/orders', {
    code,
    period_id: 1,
    options: {
      device: 0,
      module: 0,
      sms: 0,
    },
  })

export const getCreditCards = () => Http.get('/credit-cards').then((res) => res.data)

export const cancelReserved = () => Http.delete('/services/main/reserved')

export const orderPriceCalc = (data: Record<string, any>) => {
  return Http.post('/orders/price', data)
}


export const usePriceMutate=()=>{
  const queryClinet=useQueryClient()
  return useMutation(orderPriceCalc,{
    onSuccess(data, variables, context) {
      queryClinet.setQueryData('order-price', data?.data);
    },
  })
}

export const upgradePlan = (data:Record<string,any>) =>
  Http.post('/orders', data)

export const redeemStatus=()=>Http.get('/credits/redeem-status')

export const redeemSubmit=(coupon:string)=>Http.post('/credits/redeem',{coupon})
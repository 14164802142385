import axios from 'axios'
import store from '../../../setup/redux/Store'
import { actionTypes } from '../../modules/auth'

const baseUrl = process.env.REACT_APP_API_URL
const baseUrlV1 = process.env.REACT_APP_API_URL_NOT_VERSION
// Configs

const configs = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status: any) {
    return status >= 200 && status < 400
  },
}

const configsV1 = {
  baseURL: baseUrlV1,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status: any) {
    return status >= 200 && status < 400
  },
}

const getFromStore = () => {
  const token = store.getState()?.auth?.accessToken
  return {
    token: `Bearer ${token ?? ''}`,
  }
}

const handleError = (error: any) => {
  return Promise.reject(error)
}

// Fetch api type A: axios with authorization, header configs, ...
const api = axios.create(configs)

api.interceptors.request.use((config) => {
  const {token} = getFromStore()
  config.headers = {...config.headers, authorization: token}
  return config
}, handleError)

api.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    showError(error)
    return Promise.reject(error)
  }
)

export const showError = ({response}: any) => {
  switch (response?.status) {
    case 401:
      store.dispatch({type:actionTypes.Logout})
      break

    default:
      break
  }
}

// Fetch api type B: axios just with header config
export const _axios = axios.create(configs)
export const _axiosV1 = axios.create(configsV1)

_axios.interceptors.request.use((config) => {
  const {token} = getFromStore()
  config.headers = {...config.headers, authorization: token}
  return config
}, handleError)

_axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    showError(error)
    return Promise.reject(error)
  }
)
_axiosV1.interceptors.request.use((config) => {
  const {token} = getFromStore()
  config.headers = {...config.headers, authorization: token}
  return config
}, handleError)

_axiosV1.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    showError(error)
    return Promise.reject(error)
  }
)

const Http = {
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
  patch: api.patch,
}

export default Http

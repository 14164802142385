import React from 'react'

function TabDEsc({ children, id, active }) {
    return (
        <>
            {active == false ? <div
                className="fs-5 tab-pane fade"
                id={`kt_tab_pane_${id}`}
                role="tabpanel"
            >
                {children}
            </div> :

                <div
                    className="fs-5 tab-pane fade active show"
                    id={`kt_tab_pane_${id}`}
                    role="tabpanel"
                >
                    {children}
                </div>
            }
        </>
    )
}

export default TabDEsc

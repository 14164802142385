import React, {Fragment} from 'react'
import {useQuery} from 'react-query'
import {getServersList} from '../../../../services/Maintenance'
import WaveSpinner from '../../../modules/global/WaveSpinner'
import {IGroup} from '../../../store/groupStore/types'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import {Checkbox, Divider} from '@mui/material'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

interface IProps {
  onSelect: (id: number) => void
  serverId: number | null
}

const ServerSelect = (props: IProps) => {
  const {onSelect, serverId} = props
  const intl = useIntl()
  const {data: groupLists, isLoading}: any = useQuery(['servers'], getServersList)

  return (
    <div
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
      }}
      className='pe-2'
    >
      <h3 className='mb-6 text-center'>{intl.formatMessage({id: 'LABELS.SELECT.SERVER'})}</h3>
      {isLoading && <WaveSpinner isLoading />}
      {!!groupLists?.data?.length ? (
        groupLists?.data?.map((group: IGroup) => {
          return (
            <Fragment key={group.id}>
              <Divider>
                <h5 className='m-0 fw-bold'>{group?.name}</h5>
              </Divider>

              <List component='nav' className='mb-4' sx={{"& .Mui-selected":{
                backgroundColor:"var(--kt-primary) !important"
              }}}>
                {!!group?.devices?.length ? (
                  group?.devices?.map((device) => {
                    return (
                      <ListItemButton
                        selected={serverId === device.id}
                        onClick={(event) => onSelect(device.id)}
                        className='d-flex align-items-center justify-content-between p-2 rounded mt-1'
                        key={device.id}
                      >
                        <div className='d-flex gap-2 align-items-center'>
                          <KTSVG
                            path={
                              device?.statistics?.description?.type === 'linux'
                                ? '/media/icons/linux.svg'
                                : '/media/icons/windows.svg'
                            }
                            svgClassName='w-30px h-30px'
                          />
                          <strong>{device?.label}</strong>
                        </div>

                        {/* <Checkbox
                          checked={serverId === device.id}
                          sx={{visibility: serverId === device.id ? 'visible' : 'hidden'}}
                        /> */}
                      </ListItemButton>
                    )
                  })
                ) : (
                  <div className='text-center fs-7 pt-4'>
                    {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
                  </div>
                )}
              </List>
              {!!group?.subgroups?.length &&
                group?.subgroups?.map((subgroup) => {
                  return (
                    <Fragment key={subgroup.id}>
                      <Divider>
                        <h5 className='m-0 fw-bold'>{subgroup?.name}</h5>
                      </Divider>
                      <List component='nav' className='mb-4' sx={{"& .Mui-selected":{
                backgroundColor:"var(--kt-primary) !important"
              }}}>
                        {!!subgroup?.devices?.length ? (
                          subgroup?.devices?.map((subgroupDevice) => {
                            return (
                              <ListItemButton
                                selected={serverId === subgroupDevice.id}
                                onClick={(event) => onSelect(subgroupDevice.id)}
                                className='d-flex align-items-center justify-content-between p-2 rounded mt-1'
                                key={subgroupDevice.id}
                              >
                                <div className='d-flex gap-2 align-items-center'>
                                  <KTSVG
                                    path={
                                      subgroupDevice?.statistics?.description?.type === 'linux'
                                        ? '/media/icons/linux.svg'
                                        : '/media/icons/windows.svg'
                                    }
                                    svgClassName='w-30px h-30px'
                                  />
                                  <strong>{subgroupDevice?.label}</strong>
                                </div>

                                {/* <Checkbox
                                  checked={serverId === subgroupDevice.id}
                                  sx={{
                                    visibility:
                                      serverId === subgroupDevice.id ? 'visible' : 'hidden',
                                  }}
                                /> */}
                              </ListItemButton>
                            )
                          })
                        ) : (
                          <div className='text-center fs-7 pt-4'>
                            {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
                          </div>
                        )}
                      </List>
                    </Fragment>
                  )
                })}
            </Fragment>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default ServerSelect

import {FC, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import SingleCreditCardBilling from './SingleComponents/SingleCreditCardBilling'
import {useIntl} from 'react-intl'

import {Stack } from"@mui/material"


const BillingCreditCards: FC<{creditCardsDataHandler: any,creditcardsnumber?:any}> = ({creditCardsDataHandler,creditcardsnumber=3}) => {
  const intl = useIntl()
  // const mutateCreditCard = CreditCards()
  // const [loadingCreditCard, setLoadingCredit] = useState(true)
  // const [creditCardData, setCreditCardData] = useState<any>('')
  // const creditCardDataGetter = () => {
  //   mutateCreditCard.mutate('', {
  //     // dat set on the state
  //     onSuccess: (data: any) => {
  //       setCreditCardData(data)
  //       setLoadingCredit(false)
  //     },
  //     onError: () => {
  //       notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
  //     },
  //   })
  // }
  useEffect(() => {
    creditCardsDataHandler.creditCardDataGetter()
  }, [])
  return (
    <div className='main-service-container step2  mt-4'>
    
      <div className='p-3 border border-color-light rounded '>
        <div className='tab-content d-flex justify-content-between'>
          <div className='tab-content mt-2'>
            {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.CreditCards'})}
          </div>
          <Link to={`/billing/credit-cards`}>
            <Button variant='secondary' size='sm'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.AllCreditCards'})}
            </Button>
          </Link>
        </div>
        <hr className='my-5' />
        {creditCardsDataHandler.loadingCreditCard ? (
          <div className='text-center d-flex align-items-center justify-content-center' style={{minHeight: '110px'}}>
           <Spinner  animation='grow' variant='primary'/>
          </div>
        ) : (
          <Stack direction={"row"} flexWrap="wrap" gap={2}>
            {creditCardsDataHandler.creditCardData &&
              creditCardsDataHandler.creditCardData.data.map(
                (itm: any, idx: any) =>
                  itm.default === 1 && <SingleCreditCardBilling key={itm.id} data={itm} />
              )}
            {creditCardsDataHandler.creditCardData &&
              creditCardsDataHandler.creditCardData.data.map(
                (itm: any, idx: any) =>
                  idx < creditcardsnumber &&
                  itm.default === 0 && <SingleCreditCardBilling key={itm.id} data={itm} />
              )}
            {creditCardsDataHandler.creditCardData &&
              creditCardsDataHandler.creditCardData.data.length === 0 && (
                <p className='text-center pt-10 w-100'>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}{' '}
                  <Link to={`/billing/credit-cards`}>
                    ({intl.formatMessage({id: 'ACTIONS.ADD'})})
                  </Link>
                </p>
              )}
          </Stack>
        )}
      </div>
    </div>
  )
}

export default BillingCreditCards

import React, {FC} from 'react'
import {Col, Row} from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import TitleAndValues from '../../../../../modules/global/UI/TitleAndValues'
import { formatPrice } from '../../../../../../helpers/billing'

const SingleCredit: FC<{data?: any}> = ({data}) => {
  const intl = useIntl()

  return (
    <Row className='mt-4'>
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <Row style={{width: '100%'}}>
              <Col lg={1} xs={2}>
                {Boolean(data.active) ? (
                  <div title={intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen043.svg'
                      className='svg-icon-1 text-success m-n2 '
                    />
                  </div>
                ) : (
                  <div title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.inactive'})}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-1  text-danger m-n2 '
                    />
                  </div>
                )}
              </Col>
              <Col lg={5} xs={4}>
                <div>
                  <strong>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Amount'})}</strong>
                  <br /> {formatPrice(data?.amount)}
                </div>
              </Col>
              <Col lg={6} xs={6}>
                <div>
                  <strong>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.PaymentType'})}</strong>
                  <br />
                  {data.readable_payment_type}
                </div>
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            <TitleAndValues
              title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Balance'})}
              value={data.balance}
              noTitleWidth
            />

            {data.reason && (
              <TitleAndValues
                title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Reason'})}
                value={data.reason}
                noTitleWidth
              />
            )}
            {data.date && (
              <TitleAndValues
                title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Date'})}
                value={data.date}
                noTitleWidth
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Row>
  )
}

export default SingleCredit


import { Plan } from '../app/pages/Billing/NewBilling/Plans'
import {deepSearch} from './deepSearch'

export function formatPrice(price: number, currencySymbol: string = '$'): string {
  if (isNaN(price)) {
    return '$ 0.00'
  }

  const roundedPrice = Math.round(price * 100) / 100
  const priceString = roundedPrice.toFixed(2)
  const parts = priceString.split('.')
  const wholeNumberPart = parts[0]
  const decimalPart = parts[1]
  const formattedWholeNumberPart = wholeNumberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${currencySymbol} ${formattedWholeNumberPart}.${decimalPart}`
}

export const formatLabelPlan = (plan: Plan, active?: boolean): string => {
  if (!plan || !plan.checks || !plan.sms || !plan.price) {
    return ''
  }

  let planString = ``
  if (active) {
    planString += '[ACTIVE] '
  }
  if (plan?.name) {
    planString += ` ${plan.name}: `
    
  }
  if (plan.servers) {
    planString += `${plan.servers} Server + `
  }
  planString += `${plan.checks} Uptime checks + ${plan.sms} SMS = $ ${plan.price}`
  return planString
}

export const findPlanByTerm = (plans: Plan[], key: string, value: string): Plan => {
  let findedPlan = deepSearch(plans, key, (k, v) => v === value)

  

  return findedPlan as Plan
}


export function calculatePercentage(part: number, whole: number): number {

  if (part===0&&whole===0) {
    return 0
  }

  if (isNaN(part) || isNaN(whole) ) {
    return 0;
  }
  
  const percentage = part / whole;
  if (percentage === Infinity || percentage === -Infinity) {
    return 0;
  } else if (percentage > 1) {
    return 100;
  }
  
  return Number((percentage * 100).toFixed(2));
}


export function areAllGreaterThanZero(...args: (string | number)[]): boolean {
  return args.some((arg) => {
    const numArg = Number(arg);
    return !isNaN(numArg) && numArg > 0;
  });
}


/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {
  getPaidPlanPricingOptionAction,
  getSMSPlanPricingOptionAction,
} from '../../../../store/billing/actions'
import {CurrentServiceChart} from './CurrentServiceChart'
import Modal from 'react-bootstrap/Modal'

import Reserved from '../../../../store/billing/reserved'
import useReservedService from '../../../../../services/Queries/billing/useReservedService'
import ReservedService from '../ReservedService'

export function MyVerticallyCenteredModal(props: any) {
  const intl = useIntl()
  return (
    <Modal show={props.show} onHide={props.onHide}  size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: 'ALERT.SURE'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.FreePlan'})}</p>
      </Modal.Body>
      <Modal.Footer>
        {props.loading && (
          <div className='spinner-grow text-dark spinner-border-sm' role='status'>
            <span className='sr-only'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Loading'})}
            </span>
          </div>
        )}
        <button className='btn btn-secondary btn-sm' onClick={props.onHide}>
          {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
        </button>
        <Button className='btn btn-primary btn-sm' onClick={props.onSubmit}>
          {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Proceed'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CurrentService: FC<any> = ({setSelectedTab}) => {
  const mainService: any = useSelector<RootState>(
    (state: any) => state.billingReducer.mainService
  ) as any
  const {data ,refetch}=useReservedService()


  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    if (mainService) {
      dispatch(getPaidPlanPricingOptionAction())
      dispatch(getSMSPlanPricingOptionAction())
    }
  }, [])
  
  const reservedMutation = Reserved()
  useEffect(() => {
  
    reservedMutation.mutate('', {
      onSuccess: (data: any) => {
      
      },
      onError: () => {
        // notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
    
      },
    })
  }, [])



  return (
    <div className=''>
      
      {data&&<ReservedService service={data} refetch={refetch}/>}
       
      <div className='min-h-250px'>
        {mainService?.options && (
          <Row>
            <Col lg={4} xs={12} className='item-chart'>
              <h3 className='text-center'>{intl.formatMessage({id: 'LABELS.SERVERS'})}</h3>
              <div>
                <CurrentServiceChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='primary'
                  chartHeight='200px'
                  labelValue={
                    mainService.options.device.used + '/' + mainService.options.device.quantity
                  }
                  percentValue={
                    Boolean(mainService.options.device.used / mainService.options.device.quantity)
                      ? (
                          (mainService.options.device.used / mainService.options.device.quantity) *
                          100
                        ).toFixed(2)
                      : 0
                  }
                />
              </div>
            </Col>
            <Col lg={4} xs={12} className='item-chart'>
              <h3 className='text-center'>{intl.formatMessage({id: 'LABELS.CHECKS'})}</h3>
              <div>
                <CurrentServiceChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='primary'
                  labelValue={
                    mainService.options.module.used + '/' + mainService.options.module.quantity
                  }
                  chartHeight='200px'
                  percentValue={
                    Boolean(mainService.options.module.used / mainService.options.module.quantity)
                      ? (
                          (mainService.options.module.used / mainService.options.module.quantity) *
                          100
                        ).toFixed(2)
                      : 0
                  }
                />
              </div>
            </Col>
            <Col lg={4} xs={12} className='item-chart'>
              <h3 className='text-center'>{intl.formatMessage({id: 'LABELS.SMS'})}</h3>
              <div>
                <CurrentServiceChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='primary'
                  chartHeight='200px'
                  labelValue={mainService.options.sms.used + '/' + mainService.options.sms.quantity}
                  percentValue={
                    Boolean(mainService.options.sms.used / mainService.options.sms.quantity)
                      ? (
                          (mainService.options.sms.used / mainService.options.sms.quantity) *
                          100
                        ).toFixed(2)
                      : 0
                  }
                />
              </div>
            </Col>

         
          </Row>
        )}
      </div>
    </div>
  )
}

export default CurrentService

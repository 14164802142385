
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import Cards from 'react-credit-cards'

const SingleCreditCardBilling: FC<{data: any}> = ({data}) => {
  const intl = useIntl()

  return (
    <>
    <Cards
        cvc={data.expire_at}
        expiry={data.expire_at}
        name={data.owner}
        number={data.number}
        issuer={data?.type}
        preview
        
      />
      {/* <Card sx={{display: 'flex', flexWrap: 'wrap',width:"100%"}} className='bg-secondary p-0 text-dark mb-3'>
        <CardMedia
          component='img'
          sx={{width: 100 ,objectFit:"contain"}}
          image={`./media/images/logos/CreditCard/${data?.type}.png`}
        />
        <Box sx={{display: 'flex', flexDirection: 'column'}} className="fs-7">
          <CardContent sx={{flex: '1 0 auto'}} className="p-4">
            <TitleAndValues
              title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Owner'})}
              value={data.owner}
              noTitleWidth
            />
           <TitleAndValues
              title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Number'})}
              value={data.number}
              noTitleWidth
            />
           <TitleAndValues
              title={intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Exp'})}
              value={data.expire_at}
              noTitleWidth
            />
          
            {data.default == 1 && (
              <div className='badge bg-primary'>
                {' '}
                {/* {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.default'})} 
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.default'})}
              </div>
            )}
          </CardContent>
        </Box>
      </Card> */}
    </>
  )
}

export default SingleCreditCardBilling

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {notify} from '../../../helpers'
import ChargeStripe from '../../store/billing/ChargeStripe'
import {Link} from 'react-router-dom'
import {formatPrice} from '../../../helpers/billing'
import {Spinner} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { useSocket } from '../../../socket/client/client'
import useIsDigitalOceanUser from '../../Hooks/useIsDigitalOceanUser'
import { _axios } from '../../store/core/inceptor'
import MuiTextField from '../../modules/global/UI/MuiTextField'
import MuiSelect from '../../modules/global/UI/MuiSelect'
import { MenuItem } from '@mui/material'

function MyVerticallyCenteredModal(props: any) {
  const intl = useIntl()
  const [creditcard_id, setCreditcard_id] = useState<any>('')
  const [dataAgreed, setdataAgreed] = useState<any>(false)
  const ChargeStripeMutation = ChargeStripe()
  const [loading, setloading] = useState<Boolean>(false)
  const isDigitalOcean = useIsDigitalOceanUser()

  const chargeHandler = () => {
    if (isDigitalOcean) {
      return
    }
    setloading(true)
    if (creditcard_id && dataAgreed) {
      ChargeStripeMutation.mutate(
        {
          amount: props.data.amount,
          currency: 'USD',
          creditcard_id: creditcard_id,
          id: props.data.id,
        },
        {
          onSuccess: () => {
            notify({
              type: 'success',
              message: intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.orderPaid'}),
            })
            setloading(false)

            // props.invoiceAndOrderGetter.orderDataGetter('3', '0')
            props.onHide()
            // setTimeout(() => {
            //   props.invoiceAndOrderGetter.invoicesDataGetter(`?active_status=active&per_page=5`)
            // }, 3000)
          },
          onError: (err: any) => {
            const error = err.response && (err.response || err.message)
            let data = error?.data
            console.error('data error ', data)
            setloading(false)

            if (!data) {
              notify({
                type: 'error',
                message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
              })
              return
            }
            let messages = []

            for (let i in data) {
              messages.push(
                <>
                  {data[i]}
                  <br />
                </>
              )
            }
            notify({type: 'error', message: <>{messages}</>})
          },
        }
      )
    } else {
      notify({type: 'meta.error', message: intl.formatMessage({id: 'BULK_CHECK_FORM_ERROR-NOTIF'})})
      setloading(false)
    }
  }
  useEffect(() => {
    props.creditCardData?.data?.map((itm: any) =>
      itm.default === 1 ? setCreditcard_id(itm.id) : ''
    )
  }, [props.creditCardData?.data])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Pay invoice'})}
          {' #'}
          {props.data.code}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='card mb-5 mb-xl-10 pt-5 pb-5'>
          {props.creditCardData && props.creditCardData.data.length == 0 ? (
            <div className='card-body pt-2 pb-1 px-20'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.ZEROCARD'})}
              <Link to={`/billing/credit-cards`}>({intl.formatMessage({id: 'ACTIONS.ADD'})})</Link>
            </div>
          ) : (
            <div>
              <div className='card-body pt-2 pb-1 px-20'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Amount'})}
                </label>
                <MuiTextField
                  // className='form-control form-control form-control-lg form-control-solid'
                  type='text'
                  disabled={true}
                  value={'$' + props.data.amount}
                />
              </div>
              <div className='card-body pt-2 pb-1 px-20'>
                <label className='form-label'>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.CreditCards'})}
                </label>
                <MuiSelect
                  // className='form-select form-select-solid text-hover-primary'
                  // data-kt-select2='true'
                  // data-placeholder='Select option'
                    // data-allow-clear='true'
                  placeholder='Select option'
                  value={creditcard_id}
                  onChange={(e) => {
                    setCreditcard_id(e.target.value)
                  }}
                >
                  {/* <option value={''}></option> */}
                  {props.creditCardData &&
                    props.creditCardData.data.length > 0 &&
                    props.creditCardData.data.map((itm: any) => (
                      <MenuItem value={itm.id} key={itm.id}>
                        {itm.number + ' - Owner: ' + itm.owner + ' - Type: ' + itm.type}
                      </MenuItem>
                    ))}
                </MuiSelect>
              </div>
              <div className='card-body pt-4 pb-1 px-20'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={dataAgreed}
                    onChange={(e) => setdataAgreed((prev: any) => !prev)}
                    id='flexCheckDefault'
                  />
                  <label className='form-check-label' htmlFor='flexCheckDefault'>
                    {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.PayAuthorize'}) +
                      ' $' +
                      props.data.amount}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* {loading && (
          <div className='spinner-grow text-dark spinner-border-sm' role='status'>
            <span className='sr-only'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Loading'})}
            </span>
          </div>
        )} */}
        <button
          className='btn btn-secondary btn-sm w-100px'
          onClick={() => {
            props.onHide()
          }}
        >
          {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
        </button>
        <Button
          className='btn btn-primary btn-sm w-100px'
          onClick={chargeHandler}
          disabled={(props.creditCardData && props.creditCardData.data.length == 0) || loading}
        >
          {loading ? (
            <Spinner size='sm' animation='grow' />
          ) : (
            intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Charge'})
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const SingleInvoice: React.FC<any> = ({data, creditCardData, invoiceAndOrderGetter}) => {
  const intl = useIntl()
   const [loadingView,setLoadingView]=useState(false)
   const [loadingDownload,setLoadingDownload]=useState(false)

  
  const INVOICE_URL = process.env.REACT_APP_API_URL_NOT_VERSION
  const [modalShow, setModalShow] = React.useState(false)

 const  onInvoiceCreated =(event:any)=>{

    if (event?.new_invoice?.id===data?.id) {
      
      if (event?.new_invoice?.amount_unpaid>0) {
        
        setModalShow(true)
      }
      
    }
    
 }

 const handleViewPDF =async()=>{
  try {
    setLoadingView(true)
    const response :any= await _axios.get(`${INVOICE_URL}/client/invoices/${data.id}?pdf=1`,{
      responseType:"blob",
      headers:{
        'Accept': '*/*',
      }
    }
    )
    setLoadingView(false)
    
      // Convert the response to a Blob object
      const pdfBlob = new Blob([response?.data], { type: 'application/pdf' });

      // Create a URL for the Blob object
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF file in a new tab/window
      window.open(pdfUrl, '_blank');

      // Cleanup (not necessary in this case as opening a new window doesn't persist the URL)
      URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    setLoadingView(false)

    
  }
 }
 const handleDownloadPDF =async()=>{
  try {
    setLoadingDownload(true)
    const response :any= await _axios.get(`${INVOICE_URL}/client/invoices/${data.id}?pdf=1`,{
      responseType:"blob",
      headers:{
        'Accept': '*/*',
      }
    }
    )
    setLoadingDownload(false)
    
  // Convert the response to a Blob object
  const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

  // Create a URL for the Blob object
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Create a temporary anchor element
  const a = document.createElement('a');
  a.href = pdfUrl;
  a.download = `Invoice ${data.code}.pdf`; // Set the desired file name for download
  document.body.appendChild(a);

  // Trigger the click event on the anchor element
  a.click();

  // Cleanup
  URL.revokeObjectURL(pdfUrl);
  document.body.removeChild(a);
  } catch (error) {
    setLoadingDownload(false)

    
  }
 }

  useSocket({
    type: 'INVOICE_UPDATED',
    callBack: onInvoiceCreated,
  })

  return (
    <tr>
      <td className='ps-4'>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <p className='text-dark fw-bold mb-1 fs-7'>{data.code}</p>
          </div>
        </div>
      </td>
      <td>
        <p className='text-dark fw-bold d-block mb-1 fs-7 '>
          {!!data?.add_credit ? <span className='badge badge-info'>
            {intl.formatMessage({id:"BILLING.ADD.CREDIT"})}
          </span>:<></> }
        </p>
      </td>
      <td>
        <p className='text-dark fw-bold d-block mb-1 fs-7 '>{data.date}</p>
      </td>
      <td>
        <p className='text-dark fw-bold  d-block mb-1 fs-7'>{data.due}</p>
        <span className='text-muted fw-semobold text-muted d-block fs-7'> </span>
      </td>
      <td>
        <span className=' fs-7 fw-semobold'>
          {' '}
          {formatPrice(data?.amount)}
        </span>
      </td>
      <td>
        <p className={`text-dark fw-bold   d-block mb-1 fs-7 ${data.amount_unpaid > 0 ? 'badge badge-danger':'badge badge-success' } ` }>{formatPrice(data?.amount_unpaid)}</p>
      </td>
      <td className=''>
        {data.amount_unpaid > 0 && (
          <>
            <a
            onClick={() => setModalShow(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
            data-tip
            data-for={`btn-${data.id}-pay`}
          >
            <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-3' />
          </a>
          <ReactTooltip id={`btn-${data.id}-pay`}  effect='solid'>
            {intl.formatMessage({id:"BILLING_OPTIONS_SHOW.Pay invoice"})}
                </ReactTooltip>
          </>
        
          
        )}
         <button
          onClick={handleDownloadPDF}
          data-tip
          data-for={`btn-${data.id}-download`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
          disabled={loadingDownload}
        >
        {loadingDownload ?<Spinner size='sm' animation='grow' variant='primary'/>  :  <i className='fas fa-file-download fs-4'  />}
        </button>
        <button
          onClick={handleViewPDF}
          data-tip
          data-for={`btn-${data.id}-detail`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
          disabled={loadingView}
        >
        {loadingView ?<Spinner size='sm' animation='grow' variant='primary'/>  :  <i className='fas fa-eye fs-4'  />}
        </button>
        <ReactTooltip id={`btn-${data.id}-detail`}  effect='solid'>
        {intl.formatMessage({id:"LABELS.DETAILS"})}
      </ReactTooltip>
      <ReactTooltip id={`btn-${data.id}-download`}  effect='solid'>
        {intl.formatMessage({id:"LABELS.DOWNLOAD"})}
      </ReactTooltip>
      </td>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
        creditCardData={creditCardData}
        invoiceAndOrderGetter={invoiceAndOrderGetter}
      />
    </tr>
  )
}

export default SingleInvoice

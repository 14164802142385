import React, { FC } from 'react'
// import { KTSVG } from '../_metronic/helpers'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
    title?: string
    display: boolean
    children: React.ReactNode
    size: any
    acceptAction?: () => void
    acceptText?: string
    handleClose: () => void
    disabled?: boolean
    showFooterButtons?:boolean
    cancelActionText?:string
    bodyClass?:string
    footerClass?:string
}


const CustomModal: FC<Props> = ({
    title,
    display,
    children,
    size = "sm",
    acceptAction,
    acceptText = "",
    handleClose,
    disabled,
    showFooterButtons=true,
    cancelActionText="",
    bodyClass='',
    footerClass=''

}) => {
    const intl = useIntl();

    return (

        <Modal show={display} size={size} onHide={handleClose}  enforceFocus={false} autoFocus>
            {title &&<Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>}
            <Modal.Body className={bodyClass}>
                {children}
            </Modal.Body>
            {showFooterButtons && <Modal.Footer className={footerClass}>
                <Button variant='secondary' onClick={handleClose}>
                    {cancelActionText!==""?cancelActionText: intl.formatMessage({ id: 'ACTIONS.CANCEL' })}

                </Button>
                <Button variant='primary' disabled={disabled} onClick={acceptAction}>
                    {acceptText!==""?acceptText:intl.formatMessage({ id: 'ACTIONS.SAVE' })}
                    {disabled && <Spinner className='ms-2' animation="border" variant="light"  size="sm"/>}
                </Button>
            </Modal.Footer>}
        </Modal>
    )
}

export default CustomModal

import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'credit-cards'

const getCreditCards = (params) => {
  return Http.get(url)
}

const CreditCards = (params) => {
  return useMutation(getCreditCards)
}

export default CreditCards

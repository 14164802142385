import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = '/orders'

const OrderUpdateOrd = (body) => {
  return Http.put(`${url}/${body.id}`,body)
}

const OrderUpdate = () => {
  return useMutation(OrderUpdateOrd)
}

export default OrderUpdate

import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'quotations'

const getQuotations = (params) => {
  return Http.get(url)
}

const Quotations = (params) => {
  return useMutation(getQuotations)
}

export default Quotations

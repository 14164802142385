

import Http, {_axios} from '../app/store/core/inceptor'

const baseUrlV2 = process.env.REACT_APP_API_URL
const baseUrlV1 = process.env.REACT_APP_API_URL_NOT_VERSION





export const Profile = {
  update: (params: any) => {
    let formData = new FormData()

    formData.append('_method', 'PUT')

    for (let i in params) {
      //Don't let change widgets field
      if (i === 'widgets') {
        continue
      }

      if (i === 'file') {
        //change avatar
        formData.append('avatar', params['file'])
      } else {
        formData.append(i, params[i])
      }
    }
    return _axios.post(`${baseUrlV2}/client/profile`, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        // 'enctype' : 'multipart/form-data',
      },
    })
  },

  partialUpdate: (params: object | null) => _axios.patch(`${baseUrlV2}/client/profile`, params),

  sendEmailConfirmationLink: () => _axios.get('/resend-confirmation-email'),

  showTWOFAQRCode: () => _axios.get(baseUrlV2 + '/login/2fa/qrcode'),

  EnableTWOFA: (params: object | null) => _axios.post(baseUrlV2 + '/login/2fa/enable', params),

  disableTWOFA: () => _axios.get(baseUrlV2 + '/login/2fa/disable'),

  getClientAvatar: (id: number) =>
  _axios.get(`${baseUrlV2}/client/${id}/avatar`, {responseType: 'blob'}),
}


export const getContactInfo=(id:number|string)=>Http.get(`/contacts/${id}`)
export const getAccInfo=()=>Http.get(`/account`)
import React, {useState, useEffect} from 'react'
import InvoicesTable from '../../../../pages/Billing/InvoicesTable'
import CurrentService from '../../../../pages/Billing/MainService/CurrentService'
import InvoicesData from '../../../../store/billing/InvoicesData'

import TabDEsc from './TabDEsc'
import {notify} from '../../../../../helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import BillingOrders from '../../../../store/billing/BillingOrders'
import BillingCredits from '../../../../pages/Billing/MainService/Financials/BillingCredits'
import Credit from '../../../../store/billing/Credit'
import CreditCards from '../../../../store/billing/CreditCards'
import BillingCreditCards from '../../../../pages/Billing/MainService/Financials/BillingCreditCards'
import Quotations from '../../../../store/billing/Quotations'
import QuotationList from './QuotationList'
import useUser from '../../../../Hooks/useUser'
import {isHasAccess} from '../../../../../helpers/isHasAccess'
import useIsDigitalOceanUser from '../../../../Hooks/useIsDigitalOceanUser'
function TabWrapper() {
  const intl = useIntl()
  const user = useUser()
  const isDigitalOcean=useIsDigitalOceanUser()

  const [selected, setSelected] = useState(1)
  // invoices data handler
  const [invoicesDataList, setInvoicesDataList] = useState([])
  const [loadingInvoice, setLoadingInvoice] = useState(true)
  const invoicesMutate = InvoicesData()
  const [numberOfPages, setnumberOfPages] = useState(3)
  const invoicesDataGetter = (params) => {
    setLoadingInvoice(true)
    invoicesMutate.mutate(params ? params : '', {
      onSuccess: (data) => {
        setInvoicesDataList(data.data)
        setnumberOfPages(Number(data.total) / Number(data.per_page))
        setLoadingInvoice(false)
      },
      onError: () => {
        notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
      },
    })
  }
  const [loading, setLoading] = useState(true)
  const [ordersDataSet, setOrdersDataSet] = useState()
  const mutateBillingOrders = BillingOrders()

  const orderDataGetter = (length, status) => {
    setLoading(true)
    mutateBillingOrders.mutate(6 + `${status && `&status=${status}`}`, {
      // dat set on the state
      onSuccess: (data) => {
        setOrdersDataSet(data)
        setLoading(false)
      },
      onError: () => {
        notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
      },
    })
  }

  const invoiceDataHandler = {invoicesDataList, loadingInvoice, numberOfPages, invoicesDataGetter}
  const invoiceAndOrderGetter = {orderDataGetter, invoicesDataGetter}

  const CreditMutation = Credit()
  const [creditDataList, setCreditDataList] = useState('')
  const [loadingCredit, setloadingCredit] = useState(true)

  // credit data getter
  const creditDataGetter = (length, activeType) => {
    setloadingCredit(true)
    CreditMutation.mutate(`?per_page=${6}${activeType && `&status=${activeType}`}`, {
      // dat set on the state
      onSuccess: (data) => {
        setCreditDataList(data.data)
        setloadingCredit(false)
      },
      onError: () => {
        notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
      },
    })
  }

  const creditDataHandler = {creditDataList, loadingCredit, creditDataGetter}

  /////
  // credit cards data handlers
  const mutateCreditCard = CreditCards()
  const [loadingCreditCard, setLoadingCredit] = useState(true)
  const [creditCardData, setCreditCardData] = useState('')
  const creditCardDataGetter = () => {
    mutateCreditCard.mutate('', {
      // dat set on the state
      onSuccess: (data) => {
        setCreditCardData(data)
        setLoadingCredit(false)
      },
      onError: () => {
        notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
      },
    })
  }
  const creditCardsDataHandler = {loadingCreditCard, creditCardData, creditCardDataGetter}
  /////
  const [quotData, setQuotData] = useState()
  const [qoutLoading, setQoutLoading] = useState(false)
  const mutateQoutations = Quotations()

  const qoutDataGetter = () => {
    setQoutLoading(true)
    mutateQoutations.mutate('', {
      onSuccess: (data) => {
        setQuotData(data.data)
        setQoutLoading(false)
        if (data.data.length == 0) {
        }
      },
      onError: () => {
        setQoutLoading(false)
      },
    })
  }
  useEffect(() => {
    if (isHasAccess('billing', user)) {
      qoutDataGetter()
    }
  }, [])
  return (
    <div>
      <div className='card card-custom '>
        <div>
          <div className='d-flex justify-content-around tab-context-custom p-5 fs-7'>
            <div className={selected === 1 ? 'selected' : ''} onClick={() => setSelected(1)}>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.SERVICE'})}
            </div>
            {(isHasAccess('billing', user)&& !isDigitalOcean) && (
              <>
                <div className={selected === 2 ? 'selected' : ''} onClick={() => setSelected(2)}>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.CREDITS'})}
                </div>
                <div className={selected === 3 ? 'selected' : ''} onClick={() => setSelected(3)}>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.QUOTATIONS'})}
                </div>
                <div className={selected === 4 ? 'selected' : ''} onClick={() => setSelected(4)}>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.PAYMETHODS'})}
                </div>
                <div className={selected === 5 ? 'selected' : ''} onClick={() => setSelected(5)}>
                  {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.INVOIVES'})}
                </div>
              </>
            )}
          </div>
        </div>
        <div className='card-body p-3'>
          <div className='tab-content' id='myTabContent'>
            <TabDEsc id={1} active={selected === 1}>
             
              <CurrentService />
            </TabDEsc>
            {isHasAccess('billing', user) && (
              <>
                <TabDEsc id={2} active={selected === 2}>
                  {' '}
                  <BillingCredits creditDataHandler={creditDataHandler} opener={true} />
                </TabDEsc>
                <TabDEsc id={3} active={selected === 3}>
                  {/* This is where you can see and manage your Quotations, if you want to get a custom
              quotation you can contact our sales team. */}
                  <QuotationList quotData={quotData} />
                </TabDEsc>
                <TabDEsc id={4} active={selected === 4}>
                  {/* <Payment /> */}

                  <BillingCreditCards
                    creditCardsDataHandler={creditCardsDataHandler}
                    creditcardsnumber={6}
                  />
                </TabDEsc>
                <TabDEsc id={5} active={selected === 5}>
                  {/* <InvoiceTable /> */}
                  <InvoicesTable
                    className='mb-5 mb-xl-8'
                    invoiceDataHandler={invoiceDataHandler}
                    invoiceAndOrderGetter={invoiceAndOrderGetter}
                  />
                </TabDEsc>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabWrapper

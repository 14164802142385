import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'orders?per_page='

const getBillingOrders = (params) => {
  return Http.get(`${url}${params}`)
}

const BillingOrders = () => {
  return useMutation(getBillingOrders)
}

export default BillingOrders

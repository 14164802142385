import React, {FC} from 'react'
import {Col, Row} from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

const SingleQuote: FC<any> = (props) => {
  const NOT_VERSION_BASE = process.env.REACT_APP_API_URL_NOT_VERSION
  const intl = useIntl()

  return (
    <div className='card '>
      <div className='card-body border rounded p-0 mb-4'>
        <div className='d-flex  justify-content-between align-items-center bg-light p-4'>
          <h5 className='card-title '> {props.qtData.title}</h5>
          <a
            href={`${NOT_VERSION_BASE}/client/quotations/${props.qtData.id}?pdf=1`}
            target='_blank'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-1'
          >
            <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-3' />
          </a>
        </div>
        <p className='card-text p-4'>
          <Row className='gy-4'>
            <Col lg={6} xs={12}>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.ACCOUNT_MANAGER'})}:{' '}
              {props.qtData.account_manager_name}
            </Col>
            <Col lg={6} xs={12}>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.VALID_UNTIL'})}:{' '}
              {props.qtData.valid_until}
            </Col>
            <Col lg={6} xs={12}>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.TOTALL'})}: : ${props.qtData.total}
            </Col>
          </Row>
        </p>
      </div>
    </div>
  )
}
const QuotationList: FC<any> = ({quotData}) => {
  const intl = useIntl()
  return (
    <div>
      {quotData &&
        quotData.map((itm: any, idx: any) => {
          return <SingleQuote key={itm.id} qtData={itm} />
        })}
      {quotData && quotData.length === 0 && (
        <p className='text-center pt-10 pb-20'>
          {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
        </p>
      )}
    </div>
  )
}

export default QuotationList

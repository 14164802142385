import React from 'react'
import {useQuery} from 'react-query'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getDevice} from '../../services/uptime'
import useAccessControl from './useAccessControl'

const useServerQuery = (serverId?:string ,options?:{type?:string}) => {
  const location=useLocation()
  const params = useParams()
  const disableFetch=location?.pathname?.split("/")?.[1]==="incidents" && options?.type !=="121"
  const navigate = useNavigate()

  const id = serverId?? params?.id as string
  const serverQuery:any = useQuery(['device', id], () => getDevice(id), {
    enabled: !!id && !disableFetch,
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: true,
    onError: (error: any) => {
      if (error?.response?.status === 404) {
        navigate("/404")
      }     
    }
  })

  const hasWriteAcc=useAccessControl(`group_${serverQuery?.data?.group_id}_write`)

  
  const findIntegration = (integ: string) => {
    if (!!!serverQuery?.data?.integrations_status?.length) {
      return {}
    }

    return serverQuery?.data?.integrations_status?.find(
      (intObj: Record<string, any>) => intObj?.type_id == integ
    )
  }

  const isIntegrationOutdated = (name: string) => {
    const integration = findIntegration(name)

    return integration?.config_status == 1 && integration?.status == 0
  }

  const isIntegrationCannotCollect = (name: string) => {
    const integration = findIntegration(name)
    return integration?.config_status == 2
  }

  const hideIntegration = (name: string) => {
    const integration = findIntegration(name)
    return (
      integration?.status != 1 && !isIntegrationOutdated(name) && !isIntegrationCannotCollect(name)
    )
  }

  const isIntegrationNormal = (name: string) => {
    
    const integration = findIntegration(name)

    return integration?.status == 1
  }

  return {...serverQuery,hasWriteAcc,findIntegration,
    isIntegrationOutdated,
    isIntegrationCannotCollect,
    hideIntegration,
    isIntegrationNormal,}
}

export default useServerQuery

import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'services/main'

const getReserved = (params) => {
  return Http.get(url)
}

const Reserved = () => {
  return useMutation(getReserved)
}

export default Reserved

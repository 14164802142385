import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Accordion, Form, Spinner} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {IAddApiKey} from '../../../modules/client/setting/SettingsModel'
import * as Yup from 'yup'
import {Api} from '../../../../services/Api'
import {notify} from '../../../../helpers'
import {useQueryClient} from 'react-query'
import {createApiTokens} from '../../../../services/ApiToken'
import CopyToClipBoard from '../../../../helpers/CopyToClipBoard'
import IconButton from '../../../modules/global/IconButton'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import classes from './style.module.scss'
import useMainConfig from '../../../Hooks/useMainConfig'
import DatePickerMui from '../../../modules/global/UI/DatePickerMui'
import dayjs from 'dayjs'

const AddApiSchema = Yup.lazy(() =>
  Yup.object().shape({
    title: Yup.string().required().min(3).max(50),
  })
)

interface FormProps {
  isApiTokens?: boolean
}

const AddFormApi = ({isApiTokens = false}: FormProps) => {
  const intl = useIntl()
  const initValues = {
      title: '',
      expire_at: '',
      ...(isApiTokens ? {expires_at: moment().format('YYYY-MM-DD')} : {})
    }
  const [loading, setLoading] = useState(false)
  const [apiToken, setApiToken] = useState<string>('')
  const queryClient = useQueryClient()
  const {is_free_plan}=useMainConfig()

  const [selectedDate, setDate] = useState({
    date: dayjs().add(1,"month"),
    valid: true,
  })

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: AddApiSchema,
    validateOnChange: false,
		validateOnBlur: false,
    onSubmit: (values:any) => {
      if (isApiTokens&&is_free_plan) {
        return
      }
      setLoading(true)

      let request = isApiTokens
        ? createApiTokens({token_name: values.title, expires_at: selectedDate.date?.toISOString()})
        : Api.add(values)

      request
        .then((response: Record<string, any>) => {
          formik.resetForm()

          setLoading(false)
          if (isApiTokens) {
            queryClient.refetchQueries(['api-tokens-keys'])
            setApiToken(response.token)
          } else {
            queryClient.refetchQueries(['api-access-keys'])
          }

          notify({
            type: 'success',
            message: intl.formatMessage(
              {
                id: 'NOTIFICATIONS.ITEM_ADDED',
              },
              {name: values.title}
            ),
          })
        })
        .catch((err: any) => {
          setLoading(false)

          const error = err.response && (err.response || err.message)
          let data = error?.data
          console.error('data error ', data)
          if (!data) {
            return notify({
              type: 'error',
              message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
            })
          }

          let messages = []

          for (let i in data) {
            messages.push(
              <>
                {data[i]}
                <br />
              </>
            )
          }

          notify({type: 'error', message: <>{messages}</>})
        })
    },
  })

  // const handleChange = (date: any, type: string) => {
  //   if (date === null) return
  //   const tempDate = moment(date, 'YYYY/MM/DD')

  //   setDate({...selectedDate, date: tempDate.format('YYYY/MM/DD')})
  // }

  // const formattedValue = (type: string) => {
  //   return moment(selectedDate.date, 'YYYY/MM/DD').toDate()
  // }

  return (
    <div className={classes['form-custom-api']}>
      <Accordion className='mt-106'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <FormattedMessage id='ACCOUNT.APIS.BUTTONS.GENERATE' />
          </Accordion.Header>
          <Accordion.Body>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div>
                <label className='form-label fs-7'>
                  <FormattedMessage id='LABELS.TITLE' />
                </label>
                <input
                  type='text'
                  className='form-control form-control-white'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
              <div className='mt-5'>
                <label className='form-label fs-7'>
                  <FormattedMessage id='ACCOUNT.APIS.LABELS.EXPIRE' />
                </label>
                {isApiTokens ? (
                  <div id='datePickerStaticId'>
                    {/* <ReactDatePicker
                      customInput={<CustomInput {...formik.getFieldProps('expires_at')} />}
                      selected={formattedValue('to')}
                      onChange={(date) => handleChange(date, 'to')}
                      dateFormat='MM/dd/yyyy'
                    /> */}
                    <DatePickerMui
                    value={selectedDate.date}
                    onChange={(date)=>{
                      setDate({...selectedDate, date: date})
                    }}
                    
                    />
                  </div>
                ) : (
                  <select
                    className='form-select form-select-white'
                    {...formik.getFieldProps('expire_at')}
                  >
                    <option value='86400'>1 Day</option>
                    <option value='604800'>1 Week</option>
                    <option value='2592000'>1 Month</option>
                    <option value='31449600'>1 Year</option>
                    <option value='62899200'>2 Years</option>
                    <option value=''>Never</option>
                  </select>
                )}
              </div>

              <button
                type='submit'
                disabled={loading||(isApiTokens&&is_free_plan)}
                className='btn btn-primary w-100 my-4 btn-sm'
              >
                {!loading && <FormattedMessage id='ACCOUNT.APIS.BUTTONS.GENERATE' />}
                {loading && <Spinner animation='grow'  />}
              </button>
            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isApiTokens && apiToken && (
        <>
          <div className='alert alert-secondary text-dark border-dark d-flex align-items-center p-3 mt-5'>
            <CopyToClipBoard
              text={apiToken}
              onCopy={() => {
                notify({type: 'success', message: intl.formatMessage({id: 'NOTIFICATIONS.COPIED'})})
              }}
            >
              <IconButton icon='far fa-copy' iconSize='fs-2' />
            </CopyToClipBoard>

            <div className='d-flex flex-column ms-4'>
              <h5 className='m-0 fs-6'>{apiToken}</h5>
            </div>
          </div>
          <span className='fs-7'>{intl.formatMessage({id: 'API.TOKENS.COPY.TOKEN'})}</span>
        </>
      )}
    </div>
  )
}

export default AddFormApi

export const CustomInput = React.forwardRef(({value, onClick}: any, ref: any) => {
  return <Form.Control value={value} type='text' onClick={onClick} ref={ref}></Form.Control>
})

import moment from 'moment'
import React, {useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import {notify} from '../../../../helpers'
import {cancelReserved} from '../../../../services/Billing'
import {IReserved} from '../../../../services/Queries/billing/types'
import useUser from '../../../Hooks/useUser'
import OrderUpdate from '../../../store/billing/OrderUpdate'

interface IReservedService {
  service: IReserved
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IReserved, unknown>>
}

const ReservedService = (props: IReservedService) => {
  const {service, refetch} = props
  const orderUpdateMutation = OrderUpdate()
  const [loading, setLoading] = useState(false)
  const user = useUser()

  const intl = useIntl()

  const cancelHand = async () => {
    try {
      setLoading(true)
      await cancelReserved()
      setLoading(false)

      notify({
        type: 'success',
        message: intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.cancelsuccess'}),
      })
      refetch()
    } catch (err: any) {
      setLoading(false)
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  return (
    <div className='alert alert-dismissible alert-warning text-dark mt-4' >
      <h3 className='mb-4 fs-4'>{intl.formatMessage({id: 'RESERVED.SERVICE.LABEL'})} </h3>

      <section>
        <div className='fs-7 d-flex gap-6'>
          <p>
            <strong>{intl.formatMessage({id: 'MENU.SERVERS'})} : </strong>
            <span>
              {service?.options?.['device'].quantity} 
              {/* {service?.options?.['device'].quantity * service?.options?.['device']?.unit_price}) */}
            </span>
          </p>
          <p>
            <strong>{intl.formatMessage({id: 'LABELS.SERVER_OPTIONS.CHECKS'})} : </strong>
            <span>
              {service?.options?.['module'].quantity} 
              {/* ($
              {service?.options?.['module'].quantity * service?.options?.['module']?.unit_price}) */}
            </span>
          </p>
          <p className='m-0'>
            <strong>{intl.formatMessage({id: 'LABELS.SMS'})} : </strong>
            <span>
              {service?.options?.['sms'].quantity} 
              {/* ($
              {service?.options?.['sms'].quantity * service?.options?.['sms']?.unit_price}) */}
            </span>
          </p>
        </div>
        <div>
         
          <p className=''>
            <strong>{intl.formatMessage({id: 'PAGE.TITLES.STARTED'})} : </strong>
            <span>{moment(service.start).tz(user?.timezone).format('YYYY/MM/DD hh:mm')}</span>
          </p>
          <p className=''>
            <strong>{intl.formatMessage({id: 'LABELS.PRICE'})} : </strong>
            <span> ${service?.cost}</span>
          </p>
          <button className='btn btn-sm btn-warning w-100 w-lg-200px border-white  ' onClick={cancelHand}>
            {loading ? (
              <Spinner animation='grow' size='sm'/>
            ) : (
              intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.cancelOrder'})
            )}
          </button>
        </div>
      </section>
    </div>
  )
}

export default ReservedService

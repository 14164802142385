/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import InvoicesData from '../../store/billing/InvoicesData'
import SingleInvoice from './SingleInvoice'
import Pagination from 'react-bootstrap/Pagination'
import {notify} from '../../../helpers'
import {useIntl} from 'react-intl'
import CreditCards from '../../store/billing/CreditCards'
import MuiSelect from '../../modules/global/UI/MuiSelect'
import { MenuItem } from '@mui/material'



const InvoicesTable: React.FC<any> = ({className, invoiceDataHandler, invoiceAndOrderGetter,PropsCreditCardData}) => {
  const intl = useIntl()
  const [paidStatus, setPaidStatus] = useState('')
  const [activeStatus, setActiveStatus] = useState('active')
  const [activePage, setActivePage] = useState(1)
  const mutateCreditCard = CreditCards()
  const [creditCardData, setCreditCardData] = useState<any>('')
  const creditCardDataGetter = () => {
    mutateCreditCard.mutate('', {
      // dat set on the state
      onSuccess: (data: any) => {
        setCreditCardData(data)
      },
      onError: () => {
        notify({type: 'error', message: intl.formatMessage({id: 'ERROR.500.DESCRIPTION'})})
      },
    })
  }
  useEffect(() => {
    creditCardDataGetter()
  }, [])

  useEffect(() => {
    invoiceDataHandler.invoicesDataGetter(`?paid_status=unpaid&active_status=active&per_page=5`)
  }, [])

  useEffect(() => {
    invoiceDataHandler.invoicesDataGetter(
      `?paid_status=${paidStatus}&active_status=active&per_page=5&page=${activePage}`
    )
  }, [activePage])

  //pagination
  let active = activePage
  let items = []
  for (let number = 1; number <= invoiceDataHandler.numberOfPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => {
          setActivePage(number)
        }}
      >
        {number}
      </Pagination.Item>
    )
  }
  return (
    <div className={`${className} position-relative`}> 
    
    <div className='p-3 border border-color-light rounded '>

      {/* begin::Header */}
      <div className=' mb-4 d-flex align-items-center justify-content-between'>
        <div className=''>
          <h3>{intl.formatMessage({id: 'LABELS.Invoices'})}</h3>
        </div>
        <div className='d-flex'>
          <MuiSelect
            className='w-100px'
            value={paidStatus}
            displayEmpty
            onChange={(e) => {
              setPaidStatus(e.target.value)
              invoiceDataHandler.invoicesDataGetter(
                `?paid_status=${e.target.value}&active_status=${activeStatus}&per_page=5`
              )
            }}
          >
            <MenuItem value=''>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.All'})}</MenuItem>
            <MenuItem value='paid'>{intl.formatMessage({id: 'LABELS.PAID'})}</MenuItem>
            <MenuItem value='unpaid'> {intl.formatMessage({id: 'LABELS.UNPAID'})}</MenuItem>
          </MuiSelect>
          <MuiSelect
            // className='form-select form-select-solid text-hover-primary'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={''}
            value={activeStatus}
            style={{display: 'none'}}
            onChange={(e) => {
              setActiveStatus(e.target.value)
              invoiceDataHandler.invoicesDataGetter(
                `?paid_status=${paidStatus}&active_status=${e.target.value}&per_page=5`
              )
            }}
          >
            <MenuItem value=''>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.All'})}</MenuItem>
            <MenuItem value='active'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Active'})}
            </MenuItem>
            <MenuItem value='inactive'>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.inactive'})}
            </MenuItem>
          </MuiSelect>
        </div>
      </div>
      <hr className='text-dark'/>
      {/* end::Header */}
      <div className=''>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4  rounded-start '>{intl.formatMessage({id: 'LABELS.ID'})}</th>
                <th className=' '></th>
                <th>{intl.formatMessage({id: 'LABELS.DATE'})}</th>
                <th>{intl.formatMessage({id: 'LABELS.DueDATE'})}</th>
                <th>{intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Amount'})}</th>
                <th>{intl.formatMessage({id: 'LABELS.UNPAID'})}</th>
                <th className='  rounded-end pe-5'>
                  {intl.formatMessage({id: 'LABELS.Action'})}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {invoiceDataHandler.invoicesDataList &&
                invoiceDataHandler.invoicesDataList.map((itm: any) => {
                  return (
                    <SingleInvoice
                      key={itm.id}
                      data={itm}
                      creditCardData={PropsCreditCardData}
                      invoiceAndOrderGetter={invoiceAndOrderGetter}
                    />
                  )
                })}
            </tbody>

            {/* end::Table body */}
          </table>
          {invoiceDataHandler.invoicesDataList &&
            invoiceDataHandler.invoicesDataList.length === 0 && (
              <p className='text-center pt-10'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
              </p>
            )}
        </div>

        <div className='d-flex justify-content-center'>
          <Pagination>{items}</Pagination>
          {invoiceDataHandler.loadingInvoice && (
            <div className='spinner-grow text-dark spinner-border-sm mt-1 mx-3' role='status'>
              <span className='sr-only'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Loading'})}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>

      {/* begin::Body */}
    </div>
  )
}

export default InvoicesTable

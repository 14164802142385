import React, {FC, useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import ProgressWidget from '../../../modules/widgets/components/ProggressWidget'
import {KTSVG} from '../../../../_metronic/helpers'
import {convertByte} from '../../../modules/widgets/components/ChartAssistanr'
import classes from './style.module.scss'
import moment from 'moment';

import {
  CheckedBoldSVG,

  ExclamationSVG,
 
} from '../../../modules/widgets/components/SVGIcons'
import {
  getServerIncidentAction,
  getServerIpsAction,
  getServerUptimAction,
} from '../../../store/serverOverview/actions'
import {
 
  createCustomIncidentMessage,
  createIncidentMessages,
  diskIoToHumanReadable,
} from '../../../modules/widgets/components/Common'
import '../style.scss'
import DeviceCPUConsumer from './DeviceCPUConsumer'
import DeviceMemoryConsumer from './DeviceMemoryConsumer'
import DeviceDiskUsage from './DeviceDiskUsage'
import DeviceDiskIO from './DeviceDiskIO'
import {byteToHumanReadable} from '../../../modules/widgets/components/Common'
import IPSModal from './IPSModal'
import {TablesWidget6} from '../../../../_metronic/partials/widgets'
import ChecksStatusBox from '../../../modules/global/ChecksStatusBox'
import TitleAndValues from '../../../modules/global/UI/TitleAndValues'
import RemoveCheck from '../Checks/MonitoringChecks/RemoveCheck'
import PauseCheck from '../Checks/MonitoringChecks/PauseCheck'
import IconButton from '../../../modules/global/IconButton'
import HeartbeatTimeline from '../HeartbeatTimeline'
import LongTitle from '../../../modules/global/UI/LongTitle'
import ReactTooltip from 'react-tooltip'
import useServerQuery from '../../../Hooks/useServerQuery'
import Flags from '../../../modules/global/UI/Flags'
import useUser from '../../../Hooks/useUser'
import AddIntegration from '../Integrations/AddIntegration'
import PaidModal from '../../../modules/global/PaidModal'
import BandWidthTable from '../BandWidth'



const types: any = {
  20: 'PING',
  21: 'HTTP(S)',
  22: 'DNS',
  23: 'FTP',
  24: 'SMTP',
  25: 'POP3',
  26: 'IMAP',
  27: 'SSL',
  28:"TCP",
  29:"UDP"
}
const ServerDetails: FC = () => {
  const serverInfo: any = useSelector<RootState>((state) => state.serverView.detail) as object
  const ips: any = useSelector<RootState>((state) => state.serverView.ips) as object
  const serverUptime: any = useSelector<RootState>((state) => state.serverView.uptime) as object
  const navigate = useNavigate()
  const user=useUser()
  const [isLimitModal, setIsLimitModal] = useState<boolean>(false)

  const pendingGetUptime: any = useSelector<RootState>(
    (state) => state.serverView?.pendingGetUptime
  ) as any
  const pendingGetIncident: any = useSelector<RootState>(
    (state) => state.serverView?.pendingGetIncident
  ) as any

  const serverIncident: any = useSelector<RootState>((state) => state.serverView.incident) as object
  const dispatch = useDispatch()
  const params = useParams()
  const [displayIPS, setDisplayIPS] = useState(false)

  const [selectedPeriod, setSelectedPerios] = useState('this_month')
  const [selectedIncident, setSlectedIcident] = useState('open')
  const intl = useIntl()
  useEffect(() => {
    dispatch(getServerIpsAction(params.id))
  }, [])

  useEffect(() => {
    dispatch(getServerUptimAction({id: params.id, period: selectedPeriod}))
  }, [selectedPeriod])

  useEffect(() => {
    dispatch(getServerIncidentAction({id: params.id, query: 'status=' + selectedIncident}))
  }, [selectedIncident])

  const hasDocker = () => {
    if (serverInfo?.docker?.length === 0) {
      return false
    }

    for (let i in serverInfo?.docker) {
      if (serverInfo?.docker[i].length > 0) {
        return true
      }
    }

    return false
  }
  const serverQuery=useServerQuery()


   // Parse the UTC date string
  const lastUpdateCustomTimezone = moment.utc(serverInfo?.statistics?.last_update_at, "YYYY-MM-DD HH:mm:ss").tz(user.timezone);
  

  const integrationsName = [
    'Nginx',
    'Redis',
    'Keydb',
    'Apache',
    'Fpm',
    'Supervisor',
    'Docker',
    'Mysql',
    'HAProxy',
    'Mongo',
    'Netstat',
    'IIS',
    'Postgresql',
  ]

  const existedIntegrations = integrationsName.filter(
    (integration) => !serverQuery?.hideIntegration(integration)
  )


  return (
    <div className='py-5'>
      <HeartbeatTimeline />

      <Row className='gy-6'>
        <Col lg={6}>
          <div className='card card-custom card-stretch shadow'>
            {serverInfo.description && (
              <div className='card-body min-h-400  p-4 p-lg-6'>
                <div>
                  <h3>{intl.formatMessage({id: 'LABELS.DESCRIPTION'})}</h3>
                  <hr />
                </div>
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.HOSTNAME'})}
                  value={serverInfo.hostname}
                />
                <TitleAndValues title={intl.formatMessage({id: 'LABELS.MAIN_IP'})} value={ips[0]} />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.OS'})}
                  value={
                    <div className='d-flex align-items-center gap-3'>
                      <KTSVG
                        path={
                          serverInfo?.description?.type === 'linux'
                          ? '/media/icons/linux.svg'
                          : serverInfo?.description?.type === 'windows'
                          ? '/media/icons/windows.svg'
                          : !serverInfo?.description?.type
                          ? '/media/icons/duotune/general/gen046.svg'
                          : ''
                        }
                        svgClassName='w-25px h-25px'
                      />
                      {serverInfo.description?.os}
                    </div>
                  }
                />

                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.PROCESSOR'})}
                  value={serverInfo?.description?.cpu?.model_name}
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABEL.TOTAL.MEMORY'})}
                  value={convertByte(serverInfo?.statistics?.memory_usage?.total)}
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.TOTAL_CORES'})}
                  value={serverInfo.description?.cpu?.total}
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.TIMEZONE'})}
                  value={serverInfo.description?.timezone}
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.IPS'})}
                  value={
                    <>
                      {ips.length}
                      {` `}
                      {ips.length > 0 && (
                        <span className='display-ip-spn' onClick={() => setDisplayIPS(true)}>
                          (Show)
                        </span>
                      )}
                    </>
                  }
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.UPTIME'})}
                  value={serverInfo.description.uptime}
                />
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABEL.XITOGENT.VERSION'})}
                  value={
                    <section className=''>
                      {serverInfo?.statistics?.version}
                      {serverInfo?.statistics?.update && 
                      <a
                        href={
                          serverInfo?.platform === 0
                            ? 'https://xitoring.com/docs/xitogent/linux-server/update-xitogent/'
                            : 'https://xitoring.com/docs/xitogent/windows-server/windows-xitogent-update/'
                        }
                        target={"_blank"}
                        className='ms-3'
                      >
                        {intl.formatMessage({id: 'LABELS.UPDATE.AVAILABLE'})}
                        <span className='fas fa-angle-right ms-1' />
                      </a>}
                    </section>
                  }
                />
                 <TitleAndValues
                  title={intl.formatMessage({id: 'NODE'})}
                  value={<div className='d-flex gap-1 align-items-center'>
                    <Flags country={serverInfo?.node?.country}/>
                    {serverInfo?.node?.city}

                  </div>}
                />
               
                <TitleAndValues
                  title={intl.formatMessage({id: 'LABELS.UPDATED'})}
                  value={!lastUpdateCustomTimezone?.unix()? "-": lastUpdateCustomTimezone?.fromNow()}
                />
           
                {/* <Row>
                  <Col lg={12} xs={12}>
                    <ul className='software-container'>{ serverInfo.softwares.map((software:any,idx:number)=><li  className={`bg-${software}`}  ></li>)}        </ul>
                    <ul className='software-container'>
                      {Object.values(serverInfo.softwares)?.map((software: any, idx: number) => (
                        <li className={`bg-${software}`}></li>
                      ))}{' '}
                    </ul>
                  </Col>
                </Row> */}
              </div>
            )}
          </div>
        </Col>
        <Col lg={6}>
          {serverInfo && serverInfo.statistics && (
            <div className='card card-custom card-stretch shadow'>
              <div className='card-body min-h-400 statistics-context py-8 p-4 p-lg-6'>
                <div>
                  <h3>{intl.formatMessage({id: 'LABELS.STATISTICS'})}</h3>
                  <hr />
                </div>
                <Row className='mb-10'>
                  <Col lg={3} className='font-weight-bold'>
                    {intl.formatMessage({id: 'LABELS.CPU'})}:
                  </Col>
                  <Col lg={9} className='progress-wid-container'>
                    <div className='widget-context'>
                      <ProgressWidget
                        val={serverInfo?.statistics?.cpu_usage?.average}
                        min={'0%'}
                        max={'100%'}
                        unit={'%'}
                      />
                    </div>

                    <DeviceCPUConsumer data={serverInfo?.processes?.cpu_consumer} />
                  </Col>
                </Row>
                <Row className='mb-10'>
                  <Col lg={3} className='font-weight-bold'>
                    {intl.formatMessage({id: 'LABELS.MEMORY'})}:
                  </Col>
                  <Col lg={9} className='progress-wid-container'>
                    <div className='widget-context'>
                      <ProgressWidget
                        val={serverInfo?.statistics?.memory_usage?.statistics?.used_percent || 0}
                        min={`${byteToHumanReadable(
                          serverInfo?.statistics?.memory_usage?.statistics?.used || 0
                        )}`}
                        max={`${byteToHumanReadable(
                          serverInfo?.statistics?.memory_usage?.statistics?.total || 0
                        )}`}
                        unit={'%'}
                      />
                    </div>
                    <DeviceMemoryConsumer data={serverInfo?.processes?.memory_consumer} />
                  </Col>
                </Row>
                <Row className='mb-10'>
                  <Col lg={3} className='font-weight-bold'>
                    {intl.formatMessage({id: 'LABELS.DISK'})}:
                  </Col>
                  <Col lg={9} className='progress-wid-container'>
                    <div className='widget-context'>
                      <ProgressWidget
                        val={serverInfo?.statistics?.disk_usage?.statistics?.used_percent || 0}
                        min={`${(
                          serverInfo.statistics?.disk_usage?.statistics.used / 1073741824 || 0
                        ).toFixed?.(2)} GB`}
                        max={`${(
                          serverInfo.statistics?.disk_usage?.statistics?.total / 1073741824 || 0
                        ).toFixed?.(2)} GB`}
                        unit={'%'}
                      />
                    </div>
                    <DeviceDiskUsage data={serverInfo?.statistics?.disk_usage} />
                  </Col>
                </Row>
                <Row className='mb-10'>
                  <Col xs={3} className='font-weight-bold'>
                    {intl.formatMessage({id: 'LABELS.DISK_IO'})}:
                  </Col>
                  <Col xs={9} className='progress-wid-container'>
                    <div className='widget-context'>
                      <Row>
                        <Col xs={6}>
                          Read :{' '}
                          {diskIoToHumanReadable(+serverInfo.statistics?.nested_disk_io?.read || 0)}
                        </Col>
                        <Col xs={6}>
                          Write :{' '}
                          {diskIoToHumanReadable(
                            +serverInfo.statistics?.nested_disk_io?.write || 0
                          )}
                        </Col>
                      </Row>
                    </div>
                    <DeviceDiskIO data={serverInfo?.statistics?.disk_io?.partitions} />
                  </Col>
                </Row>
               {serverInfo?.platform === 0&& <Row>
                  <Col lg={3} className='font-weight-bold'>
                    {intl.formatMessage({id: 'LABELS.LOAD_AVERAGE'})}:
                  </Col>
                  <Col lg={9}>
                    <Row>
                      <Col col={4}>
                        1 Min:
                        {serverInfo.statistics.cpu_load_average &&
                          serverInfo.statistics.cpu_load_average['1min']}
                      </Col>
                      <Col col={4}>
                        5 Min:
                        {serverInfo.statistics.cpu_load_average &&
                          serverInfo.statistics.cpu_load_average['5min']}
                      </Col>
                      <Col col={4}>
                        15 Min:
                        {serverInfo.statistics.cpu_load_average &&
                          serverInfo.statistics.cpu_load_average['15min']}
                      </Col>
                    </Row>
                  </Col>
                </Row>}
              </div>
            </div>
          )}
        </Col>
        <Col lg={6} className='mt-5'>
          <TablesWidget6
            className={classes['custom_table']}
            title={intl.formatMessage({id: 'LABELS.RECENT_INCIDENTS'})}
            onFilterChange={(filter) => setSlectedIcident(filter)}
            rows={serverIncident?.data?.map((up: any, i: number) => ({...up, key: up.id}))}
            defaultSelectedFilter={'open'}
            loading={!!pendingGetIncident}
            columns={[
              {
                key: 'ic3',
                minWidth: '30%',
                maxWidth: '30%',
                getter: '',
                node: (rowData) => {
                  return (
                    <div>
                      <Link to={`/incidents/${rowData.id}`}>
                        <LongTitle
                          title={
                            rowData?.details.is_manual
                              ? createCustomIncidentMessage(rowData?.details, intl)
                              : createIncidentMessages(rowData?.details, intl)
                          }
                          key={rowData?.id}
                          className='text-primary'
                          id={rowData?.id + 'h'}
                        />
                      </Link>
                    </div>
                  )
                },

                classNameTd: 'ps-0',
              },
              {
                key: 'ic4',
                minWidth: '30%',
                maxWidth: '30%',
                getter: 'created_at_human_readable',
                classNameTd: 'ps-0 font-bold',
                name: intl.formatMessage({id: 'LABELS.DATE'}),

                node: (rowData) => (
                  <div className='text-left font-bold'>
                    <span data-tip data-for={rowData?.id + 'cr'}>
                      {rowData?.created_at_human_readable}
                    </span>
                    <ReactTooltip id={rowData?.id + 'cr'} className='tooltip-wrapper'>
                      {rowData?.created_at}
                    </ReactTooltip>
                  </div>
                ),
              },
              {
                key: 'ic5',
                minWidth: '25%',
                maxWidth: '25%',
                getter: 'created_at_human_readable',
                classNameTd: 'ps-0 ',
                name: intl.formatMessage({id: 'LABELS.UPTIME_DOWNTIME'}),

                node: (rowData) => (
                  <div>
                    {' '}
                    <LongTitle
                      title={
                        rowData?.downtime_human_readable ||
                        rowData?.resolver.downtime_human_readable
                      }
                      key={rowData?.id}
                      id={rowData?.id + ''}
                    />{' '}
                  </div>
                ),
              },
              {
                key: 'ic2',
                minWidth: '10%',
                maxWidth: '10%',
                getter: 'resolved_id',
                className: 'text-end',
                name: intl.formatMessage({id: 'LABELS.STATUS'}),
                node: (rowData) => (
                  <div className='text-end'>
                    {!rowData?.resolved_id && (
                      <div>
                        <ExclamationSVG fill={'#f1416c'} />
                      </div>
                    )}
                    {rowData?.resolved_id > 0 && (
                      <div>
                        <CheckedBoldSVG fill={'#50cd89'} />
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
            filters={[
              {
                value: 'all',
                name: intl.formatMessage({id: 'LABELS.ALL'}),
                key: 'i1',
              },

              {
                value: 'resolved',
                name: intl.formatMessage({id: 'LABELS.RESOLVED'}),
                key: 'i3',
              },
              {
                value: 'open',
                name: intl.formatMessage({id: 'LABELS.OPEN'}),
                key: 'i2',
              },
            ]}
          />
        </Col>
        <Col lg={6} className='mt-5'>
          <TablesWidget6
            className={classes['custom_table']}
            title={intl.formatMessage({id: 'LABELS.UPTIME'})}
            onFilterChange={(filter) => setSelectedPerios(filter)}
            rows={serverUptime?.map((up: any, i: number) => ({...up, key: i}))}
            defaultSelectedFilter={'this_month'}
            loading={!!pendingGetUptime}
            headerNode={
              <Link
                to={`/new?mode=add-check&server=${params.id}`}
                className='btn btn-sm btn-primary d-flex align-items-center'
              >
                {intl.formatMessage({id: 'ACTIONS.UPTIME_ADD_NEW_CHECK'})}
              </Link>
            }
            columns={[
              {
                key: 'c1',
                minWidth: '15%',
                maxWidth: '15%',
                getter: 'type',
                node: (rowData) => (
                  <ChecksStatusBox
                    type={rowData?.type}
                    status={rowData?.status}
                    is_paused={rowData?.is_paused}
                    size={'symbol-60px'}
                    font={'10px'}
                  />
                ),
                name: intl.formatMessage({id: 'LABELS.TYPE'}),
              },

              {
                key: 'c3',
                minWidth: '30%',
                maxWidth: '40%',
                getter: 'host',
                name: intl.formatMessage({id: 'LABELS.SERVER_HOST'}),
                node: (rowData) => <div>{rowData?.host || '-'}</div>,
                classNameTd: 'ps-0',
              },
              {
                key: 'c4',
                minWidth: '15%',
                maxWidth: '15%',
                getter: 'uptime_percentage',
                classNameTd: 'ps-0',
                name: intl.formatMessage({id: 'MENU.UPTIME'}),
                node: (rowData) => <div>{rowData?.uptime_percentage?.toFixed?.()} %</div>,
              },
              {
                key: 'c2',
                minWidth: '40%',
                maxWidth: '40%',
                getter: 'status',
                className: 'text-end',
                // name: intl.formatMessage({id: 'LABELS.STATUS'}),
                node: (rowData) => (
                  <div className='d-flex  gap-2 justify-content-end'>
                 {serverQuery?.hasWriteAcc&&   <PauseCheck
                      item={rowData?.check}
                      type={types[rowData.type]}
                      refetch={() =>
                        dispatch(getServerUptimAction({id: params.id, period: selectedPeriod}))
                      }
                    />}
                    <IconButton
                      icon='fas fa-external-link-alt'
                      onClick={() => navigate(`/uptime/${params?.id}?module=${rowData?.check?.id}`)}
                    />
                { serverQuery?.hasWriteAcc&&    <RemoveCheck
                      refetch={() =>
                        dispatch(getServerUptimAction({id: params.id, period: selectedPeriod}))
                      }
                      _type={types[rowData.type]}
                      _item={rowData?.check}
                    />}
                  </div>
                ),
              },
            ]}
            filters={[
              {
                value: 'today',
                name: intl.formatMessage({id: 'PROPERTY.UPTIME_TODAY'}),
                key: 't1',
              },
              {
                value: 'this_week',
                name: intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_WEEK'}),
                key: 't2',
              },
              {
                value: 'this_month',
                name: intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_MONTH'}),
                key: 't3',
              },
              {
                value: 'this_year',
                name: intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_YEAR'}),
                key: 't4',
              },
            ]}
          />
        </Col>
        <Col lg={12}>
          <BandWidthTable data={serverInfo?.statistics?.bandwidth} />
   
        </Col>
        <Col>
          <AddIntegration emptyList={!!existedIntegrations.length} setIsLimitModal={setIsLimitModal} />
        </Col>
        {/* {hasDocker() && <ServerDocker />} */}
        {/* {serverInfo?.netstat && Object.keys(serverInfo?.netstat).length > 0 && (
          <Col lg={12} className='mt-5 netstat-context'>
            <Netstate data={serverInfo.netstat} />
          </Col>
        )} */}
      </Row>
      <IPSModal ips={ips} display={displayIPS} handleClose={() => setDisplayIPS(false)} />
      <PaidModal
          title={intl.formatMessage({id: 'LABELS.LIMIT.INTEGRATIONS'})}
          open={isLimitModal}
          onClose={()=>setIsLimitModal(false)}
        />
    </div>
  )
}

export default ServerDetails

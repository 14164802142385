import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {notify} from '../../../../../../helpers'
import {RootState} from '../../../../../../setup'
import ConfirmModal from '../../../../../modules/widgets/components/ConfirmModal/ConfirmModal'
import moment from 'moment'
import {
  reqGetChecksAction,
  reqPauseCheckAction,
  resetServerDetailProps,
} from '../../../../../store/serverOverview/actions'
import './style.scss'
import {updateHeartbeat} from '../../../../../../services/Server'
import CustomTimeModal from '../../../../../modules/global/CustomTimeModal'
import {Dayjs} from 'dayjs'
import {pauseServerChecks} from '../../../../../store/serverOverview/saga/requests'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { bulkResolveManual } from '../../../../../../services/incidentList'
import { useMutation } from 'react-query'

const PauseTypes: FC<{item: any; type: string; displayTypes: boolean; refetch?: any,openIncidentsCount?:number,openResolveModalHand?:()=>void,incidents?:any[]}> = ({
  item,
  type,
  displayTypes = false,
  refetch,
  openIncidentsCount,
  openResolveModalHand,
  incidents
}) => {
  const success: boolean = useSelector<RootState>(
    (state) => state.serverView.pauseCheckSuccess
  ) as boolean
  const waiting: boolean = useSelector<RootState>(
    (state) => state.serverView.pendingPauseCheck
  ) as boolean
  const {mutateAsync:mutateResolve, isLoading:loadingResolve} = useMutation({mutationFn: bulkResolveManual})

  const [display, setDisplay] = useState(false)
  const [customDisplay, setCustomDisplay] = useState(false)
  const [selectedItemToPause, setSelectedItemToPause] = useState()
  const [loading,setLoading]=useState<"pause"|"resolve_pause">()
  const [duration, setDuration] = useState(0)
  const dispatch = useDispatch()
  const params = useParams()
  const intl = useIntl()

  const handleApiRequest = async (time: any,loading:"pause"|"resolve_pause") => {
    try {
      setLoading(loading)
      if (type == '30') {
        await updateHeartbeat(item.id, {
          check: {
            pause_until: time,
          },
        })
      } else {
        await pauseServerChecks(params.id ?? '', item.id, time)
      }
      notify({
        type: 'success',
        message: intl.formatMessage(
          {id: 'NOTIFICATIONS.SUCCESS_PAUSE_CHECK'},
          {type: '', host: item?.data.host}
        ),
      })
      refetch?.()
      dispatch(resetServerDetailProps())
      dispatch(reqGetChecksAction(params.id))
      setDisplay(false)
      setLoading(undefined)

    } catch (err: any) {
      setLoading(undefined)

      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }

    //pauseServerChecks
  }
  const handlePause = (hours: number) => {
    // if (openResolveModal) {
    //   openResolveModalHand?.()
    //   return
    // }
    if (hours == 0) {
      setDuration(2114380800)
    } else {
      let tempDuration = hours * 3600
      tempDuration = Math.ceil(Date.now() / 1000) + tempDuration
      setDuration(tempDuration)
    }
    setDisplay(true)
  }

 
  const handleCustomPause = async (time: Dayjs) => {
    await handleApiRequest(time.unix(),'pause')
    setCustomDisplay(false)
  }

  const resolveAndPauseHand=async()=>{
try {
  const incidentIds=incidents?.map((incident)=>incident?.id) as number[]
  await mutateResolve({ids: incidentIds})
  await handleApiRequest(duration,'resolve_pause')
} catch (error) {
  
}
  }

  return (
    <>
      <div
        className={
          displayTypes
            ? 'types-context card card-custom card-stretch shadow display'
            : 'types-context card card-custom card-stretch shadow'
        }
      >
        <div className='card-body min-h-400 overview-uptime-context'>
          <ul>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(0)
              }}
            >
              Manual
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(1)
              }}
            >
              1 Hour
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(3)
              }}
            >
              3 Hours
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(6)
              }}
            >
              6 Hours
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(12)
              }}
            >
              12 Hours
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(24)
              }}
            >
              1 Day
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(72)
              }}
            >
              3 Days
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                handlePause(168)
              }}
            >
              1 Week
            </li>
            <li
              onClick={() => {
                setSelectedItemToPause(item)
                setCustomDisplay(true)
              }}
            >
              Custom
            </li>
          </ul>
        </div>
      </div>
      {/* <ConfirmModal
        display={display}
        title={intl.formatMessage({id: 'NOTIFICATIONS.CONFIRM_MODAL_TITLE_ARE_YOU_SURE'})}
        description={intl.formatMessage(
          {id: 'NOTIFICATIONS.CONFIRM_PAUSE_CHECK'},
          {
            type: String(item?.type_human_readable)?.toUpperCase(),
            host: item?.data?.host ? `(${item?.data?.host})` : ``,
          }
        )}
        handleClose={() => setDisplay(false)}
        handleConfirm={() => handleApiRequest(duration)}
        waiting={loading}
      /> */}

      <CustomTimeModal
        open={customDisplay}
        handleClose={() => setCustomDisplay(false)}
        onSubmit={handleCustomPause}
        pending={loading==="pause"}
      />

<Modal
      show={display}
      onHide={() => setDisplay(false)}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: 'NOTIFICATIONS.CONFIRM_MODAL_TITLE_ARE_YOU_SURE'})}
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {!!openIncidentsCount &&<p>
            {intl.formatMessage({id:"LABELS.RESOLVE.INCIDENT.COUNT.PAUSE"},{count:openIncidentsCount})}
            </p>}
          <p>
          {intl.formatMessage(
          {id: 'NOTIFICATIONS.CONFIRM_PAUSE_CHECK'},
          {
            type: String(item?.type_human_readable)?.toUpperCase(),
            host: item?.data?.host ? `(${item?.data?.host})` : ``,
          }
        )}
          </p>
        </div>
        <div className='d-flex gap-2 justify-content-end'>
          {!!openIncidentsCount ?<Button size='sm' className='w-120px' variant='secondary' onClick={resolveAndPauseHand}>
            {loadingResolve||loading==="resolve_pause" ?  <Spinner
              animation='grow' variant='primary' size='sm'
              />:intl.formatMessage({id:"LABELS,.RESOLVE.AND.PAUSE"})}
          </Button> :
          <Button size='sm' className='w-100px' variant='secondary' onClick={() => setDisplay(false)} >
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </Button>}
          <Button
            
            className='w-100px'
            onClick={() => handleApiRequest(duration,'pause')}
    
            
            size='sm'
          >
            
            {loading==="pause" ? (
              <Spinner
              animation='grow' variant='primary' size='sm'
              />
            ) : intl.formatMessage({id: 'LABELS.PAUSE'})}
          </Button>
        </div>
      </Modal.Body>
    </Modal>


    </>
  )
}

export default PauseTypes

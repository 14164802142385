import React from 'react'
import {useIntl} from 'react-intl'
import ReactTooltip from 'react-tooltip'
import {moduleTypeToHumanReadable} from '../widgets/components/Common'

interface Props {
  type: number
  status: number
  is_paused: boolean
  font?: string
  size?: 'symbol-60px' | 'symbol-45px'|'symbol-50px'
}

export  const returnStatus = (
  status: number,
  is_paused: boolean,
  is_under_maintenance: boolean = false
) => {
  if (is_under_maintenance) {
    return 'UNDER.MAINTENACE'
  }

  if (is_paused) {
    return 'PAUSE'
  }

  if (status == 1) {
    return 'UP'
  }

  if (status == 0) {
    return 'DOWN'
  }
}

const ChecksStatusBox = ({status, type, is_paused, size = 'symbol-45px', font = '10px'}: Props) => {
  const bg = is_paused
    ? 'bg-secondary'
    : status === 1
    ? 'bg-success'
    : status === 0
    ? 'bg-danger'
    : 'bg-dark'

  const id = Math.random()
  const intl = useIntl()
  return (
    <>
      <div data-tip data-for={String(id)} className={`symbol  me-2 text-uppercase ${size}`}>
        <span className={`symbol-label text-white  fw-bold ${bg} `} style={{fontSize: font}}>
          {moduleTypeToHumanReadable(type)}
        </span>
      </div>
      <ReactTooltip id={String(id)} aria-haspopup='true'>
        {intl.formatMessage({id: returnStatus(status, is_paused, false)})}
      </ReactTooltip>
    </>
  )
}

export default ChecksStatusBox

import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'invoices'

const getInvoices = (params) => {
  return Http.get(`${url}${params}`)
}

const InvoicesData = () => {
  return useMutation(getInvoices)
}

export default InvoicesData

import { Divider, Menu, MenuItem, useMediaQuery } from "@mui/material"
import { useEffect, useState, useTransition } from "react"
import { Button, Modal } from "react-bootstrap"
import { FormattedMessage, useIntl } from "react-intl"
import { useGraphFilterConfig } from "../../modules/context/MetricsPageProvider"
import { useQueryClient } from "react-query"
import SimpleDatePickerMui from "../../modules/global/UI/SimpleDatePickerMui"
import dayjs, { Dayjs } from "dayjs"
import { useParams } from "react-router-dom"

interface IGraphsFilters {
  setIntervalTime: any
  setPeriod: any
  setGridList: any
  setSyncGraph: any
  intervalTime: number
  syncGraph:boolean
  period: string
  since?: string
  setSince?: any
  until?: string
  setUntil?: any
  selectedServer?: Record<string, any> | null
  handleResetLayout?: any 
  handleSaveLayoutGlobally?: any 
  hideLayoutitems?: boolean
  customDash?:boolean
}

const GraphsFilters = ({
  setIntervalTime,
  setPeriod,
  setGridList,
  syncGraph,
  setSyncGraph,
  intervalTime,
  period,
  since,
  setSince,
  until,
  setUntil,
  selectedServer,
  handleResetLayout,
  handleSaveLayoutGlobally,
  hideLayoutitems = false,
  customDash = false
}:IGraphsFilters) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const intl = useIntl()
  const [,startTransition]=useTransition()
  const { state, dispatch } = useGraphFilterConfig();
  const params = useParams()

  const queryClient = useQueryClient()
  const refetchAllQueries = () => {
    queryClient.invalidateQueries()
  }

  const [showModal, setShowModal] = useState<boolean>(false)
  const [startvalue, setStartValue] = useState<Dayjs | null>(dayjs().startOf('month'))
  const [endvalue, setEndValue] = useState<Dayjs | null>(dayjs())

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorPeriod, setAnchorPeriod] = useState<null | HTMLElement>(null)
  const [anchorSync, setAnchorSync] = useState<null | HTMLElement>(null)
  const [anchorColumn, setAnchorColumn] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openPeriod = Boolean(anchorPeriod)
  const openSyncing = Boolean(anchorSync)
  const openColumn = Boolean(anchorColumn)
  
  const handleFilter = (type: string) => {
    setPeriod(dispatch, type)
    if (type === 'custom') {
      setShowModal(true)
    } else {
      setShowModal(false)
      setStartValue(dayjs().startOf('month'))
      setEndValue(dayjs())
    }
  }
  
  const handleChangeSince = (time: number) => {
    setSince?.(dispatch, dayjs.unix(time).format('MM/DD/YYYY'))
  }

  const handleChangeUntil = (time: number) => {
    setUntil?.(dispatch,  dayjs.unix(time).format('MM/DD/YYYY'))
  }

  const customDateHandler = () => {
    setShowModal(false)
    if (startvalue) {
      handleChangeSince(startvalue?.unix())
    }
    if (endvalue) {
      handleChangeUntil(endvalue?.unix())
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const onIntervalChange = (val: number) => {
    startTransition(()=>{
      setIntervalTime(dispatch, val)
    })
    setAnchorEl(null)
  }
  const onPeriodChange = (val: string) => {
    startTransition(()=>{
      handleFilter(val)
    })
    setAnchorPeriod(null)
  }
  
  const onColumnChange = (val: string) => {
    if (customDash) {
        startTransition(() => {
          setGridList((prevList:any) => {
            const existingItemIndex = prevList.findIndex((item:any) => item.id === params.id);
      
            if (existingItemIndex >= 0) {
              const updatedList = [...prevList];
              updatedList[existingItemIndex].value = val;
              return updatedList;
            } else {
              return [...prevList, { id: params.id, value: val }];
            }
          });
        })
    } else {
      startTransition(() => {
        setGridList(val) 
      })
    }
    setAnchorColumn(null)
  }

  const onSyncChange=(status:boolean)=>{
    startTransition(()=>{
      setSyncGraph(dispatch, status); 

    })
    setAnchorSync(null)
  }

  const getIntervalLabel = (interval: number) => {
    switch (interval) {
      case 60000:
        return intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '1'})
      case 300000:
        return intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '5'})
      case 600000:
        return intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '10'})
      case 900000:
        return intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '15'})
      case 0:
        return intl.formatMessage({id: 'LABELS.DO_NOT_REFRESH'})
      default:
        return intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '1'})
    }
  }
  const getPeriodLabel = (period:string) => {
    switch (period) {
      case 'last_hour':
        return `${intl.formatMessage({ id: 'LABELS.LAST' })} ${intl.formatMessage({ id: 'LABELS.HOUR' })}`;
      case 'last_24_hours':
        return `${intl.formatMessage({ id: 'LABELS.LAST' })} 24h`;
      case 'last_7_days':
        return `${intl.formatMessage({ id: 'LABELS.LAST' })} 7d`;
      case 'this_month':
        return `${intl.formatMessage({ id: 'LABELS.THIS' })} month`;
      case 'last_month':
        return `${intl.formatMessage({ id: 'LABELS.LAST' })} month`;
      case 'this_year':
        return `${intl.formatMessage({ id: 'LABELS.THIS' })} year`;
      case 'custom':
        return since ? `${since} - ${until}` : intl.formatMessage({ id: 'LABELS.CUSTOM' });;
      default:
        return '';
    }
  };

    
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setAnchorPeriod(null)
      setAnchorEl(null)
    })
    return () => {
      window.removeEventListener('scroll', () => {
        setAnchorPeriod(null)
        setAnchorEl(null)
      })
    }
  }, [])


  return (
    <div className='d-flex align-items-center flex-wrap justify-content-md-end gap-3 ms-auto'>
          <div>
            <Button
              id='basic-button'
              aria-controls={openSyncing ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openSyncing ? 'true' : undefined}
              onClick={(e: any) => setAnchorSync(e.currentTarget)}
              size='sm'
              className='d-flex gap-1 align-items-center justify-content-center'
              style={{minWidth:'max-content'}}
            >
                <span>{intl.formatMessage({ id: 'LABELS.SYNCING_GRAPHS' })}: {syncGraph?intl.formatMessage({ id: 'LABELS.ON' }):intl.formatMessage({ id: 'LABELS.OFF' }) }</span>
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorSync}
              open={openSyncing}
                onClose={() => setAnchorSync(null)}
                sx={{width:'9rem'}}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              elevation={1}
            >
                <MenuItem onClick={() => {onSyncChange(true)}}>
                {intl.formatMessage({id: 'LABELS.ON'})}{' '}
              </MenuItem>
              <MenuItem onClick={() => {onSyncChange(false)}}>
                {intl.formatMessage({id: 'LABELS.OFF'})}
              </MenuItem>
            </Menu>
          </div>
          {!isMobile && (
            <div>
              <Button
                id='basic-button'
                aria-controls={openColumn ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openColumn ? 'true' : undefined}
                onClick={(e: any) => setAnchorColumn(e.currentTarget)}
                size='sm'
                className='d-flex gap-1 align-items-center justify-content-center'
                style={{minWidth:'max-content'}}
              >
                <span className='fs-3  fas fa-grip-horizontal' />
                  <span>{intl.formatMessage({ id: 'LABELS.LAYOUT' })}</span>
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorColumn}
                open={openColumn}
                onClose={(e: any) => setAnchorColumn(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                elevation={1}
                >
                <span className='px-2 text-muted'>{intl.formatMessage({id: 'LABELS.COLUMN'})}</span>
                <MenuItem onClick={() => onColumnChange('mode_2')}>
                  {intl.formatMessage({id: 'LABELS.SHOW_NUM'}, {value: '2'})}{' '}
                </MenuItem>
                <MenuItem onClick={() => onColumnChange('mode_3')}>
                  {intl.formatMessage({id: 'LABELS.SHOW_NUM'}, {value: '3'})}
                </MenuItem>
                <MenuItem onClick={() => onColumnChange('mode_4')}>
                  {intl.formatMessage({id: 'LABELS.SHOW_NUM'}, {value: '4'})}
                </MenuItem>
                {!hideLayoutitems && (
                  <>
                    <Divider />
                    <span className='px-2 text-muted'>{intl.formatMessage({ id: 'LABELS.LAYOUT' })}</span>
                    <MenuItem onClick={() => {
                        handleResetLayout(selectedServer?.id)
                        setAnchorColumn(null)
                      }}>
                      {intl.formatMessage({id: 'LABELS.RESET_LAYOUT'})}
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleResetLayout()
                        setAnchorColumn(null)
                      }}>
                      {intl.formatMessage({id: 'LABELS.RESET_GLOBALY'})}
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleSaveLayoutGlobally()
                        setAnchorColumn(null)
                      }}>
                      {intl.formatMessage({id: 'LABELS.SAVE_GLOBALY'})}
                    </MenuItem>
                  </>
                )}
              
                {/* <MenuItem onClick={() => onColumnChange(2.4)}>
                  {intl.formatMessage({id: 'LABELS.SHOW_NUM'}, {value: '5'})}
                </MenuItem>
                <MenuItem onClick={() => onColumnChange(2)}>
                  {intl.formatMessage({id: 'LABELS.SHOW_NUM'}, {value: '6'})}
                </MenuItem> */}
              </Menu>
            </div>
          )}
          <div>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              size='sm'
              className='d-flex gap-1 align-items-center justify-content-center'
              style={{minWidth:'max-content'}}
            >
              <span className='fs-3 fas fa-sync-alt' />
              <span>{getIntervalLabel(intervalTime)}</span>
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              elevation={1}
            >
              <MenuItem
                onClick={() => {
                  refetchAllQueries()
                  setAnchorEl(null)
                }}
              >
                {intl.formatMessage({id: 'LABELS.UPDATE.NOW'})}
              </MenuItem>
              <MenuItem onClick={() => onIntervalChange(60000)}>
                {intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '1'})}{' '}
              </MenuItem>
              <MenuItem onClick={() => onIntervalChange(300000)}>
                {intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '5'})}
              </MenuItem>
              <MenuItem onClick={() => onIntervalChange(600000)}>
                {intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '10'})}
              </MenuItem>
              <MenuItem onClick={() => onIntervalChange(900000)}>
                {intl.formatMessage({id: 'LABELS.UPDATE_EVERY'}, {value: '15'})}
              </MenuItem>
              <MenuItem onClick={() => onIntervalChange(0)}>
                {intl.formatMessage({id: 'LABELS.DO_NOT_REFRESH'})}
              </MenuItem>
            </Menu>
          </div>
          <div>
            <Button
              id='basic-button'
              aria-controls={openPeriod ? 'basic-menu-period' : undefined}
              aria-haspopup='true'
              aria-expanded={openPeriod ? 'true' : undefined}
              onClick={(e: any) => setAnchorPeriod(e.currentTarget)}
              size='sm'
              className='d-flex gap-1 align-items-center justify-content-center'
              // style={{minWidth:'max-content'}}
            >
              <span className='fs-3 fa-solid fa-clock' />
              <span>{getPeriodLabel(period)}</span>
            </Button>
            <Menu
              id='basic-menu-period'
              anchorEl={anchorPeriod}
              open={openPeriod}
              onClose={() => setAnchorPeriod(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              elevation={1}
            >
              <MenuItem onClick={() => onPeriodChange('last_hour')}>
                {intl.formatMessage({id: 'LABELS.LAST'})} {intl.formatMessage({id: 'LABELS.HOUR'})}
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('last_24_hours')}>
                {intl.formatMessage({id: 'LABELS.LAST'})} 24h
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('last_7_days')}>
                {intl.formatMessage({id: 'LABELS.LAST'})} 7d
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('this_month')}>
                {intl.formatMessage({id: 'LABELS.THIS'})} month
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('last_month')}>
                {intl.formatMessage({id: 'LABELS.LAST'})} month
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('this_year')}>
                {intl.formatMessage({id: 'LABELS.THIS'})} year
              </MenuItem>
              <MenuItem onClick={() => onPeriodChange('custom')}>
                {intl.formatMessage({id: 'LABELS.CUSTOM'})}
              </MenuItem>
            </Menu>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <FormattedMessage id='TITLES.CHOOSE_YOUR_DATE' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex gap-2 align-items-center justify-content-center w-100 ms-auto'>
            <SimpleDatePickerMui
              value={startvalue}
              onChange={(date) => setStartValue(date)}
              label={intl.formatMessage({id: 'PAGE.TITLES.STARTED'})}
              size='small'
              height='43px'
              format='YYYY-MM-DD'
              withoutHour
            />
            <span className='fs-7'>{intl.formatMessage({id: 'LABELS.TO'})}</span>
            <SimpleDatePickerMui
              value={endvalue}
              onChange={(date) => setEndValue(date)}
              label={intl.formatMessage({id: 'END'})}
              size='small'
              height='43px'
              format='YYYY-MM-DD'
              withoutHour
            />
          </div>{' '}
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='secondary' onClick={() => setShowModal(false)}>
            <FormattedMessage id='SERVICEMODAL.BUTTON_CLOSE' />
          </Button>
          <Button className='btn btn-primary btn-sm' onClick={customDateHandler}>
            <FormattedMessage id='ACTIONS.CONFIRM' />
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
  )
}
export default GraphsFilters
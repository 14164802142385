import Http from '../app/store/core/inceptor'

export interface IgetIncidentList {
  per_page: number | string
  page: number | string
  status: string
  type: string
  group_id: string
  since?:number
  until?:number
}

export const getIncidentList = (query: IgetIncidentList) => {
  return Http.get(
    `incidents`
    ,{
      params:{
       
        ...(!!query.type && {type:query.type}),
        ...(!!query.since && {since:query.since}),
        ...(!!query.until && {until:query.until}),
        ...(!!query.page && {page:query.page}),
        ...(!!query.per_page && {per_page:query.per_page}),
        ...(!!query.status && {status:query.status}),
        ...(!!query.group_id && {group_id:query.group_id}),
      }
    }
  )
}


export const getIncidentHeatmap=()=>{
  return Http.get('/incidents/heatmap')}

export const getDownIncidents=()=>{
  return Http.get("/incidents?status=open&count_only=true")
}

export const createServerTrigger=(data:Record<string,any>)=>Http.post(`/servers/${data?.id}/metric/triggers`,data?.trigger)
export const getIncident=(id:string)=>Http.get(`/incidents/${id}`)

export const resolveManual=(id:number)=>Http.post(`/incidents/${id}/resolve`)


interface IncidentNoteData{
  id:number
  type:number
  note:string
  is_public:number
  noteId?:number
}

export const incidentNoteReq=(data:IncidentNoteData)=>Http.post(`/incidents/${data.id}/notes`,{
  type:data.type,
  note:data.note,
  is_public:data.is_public
})


export const getListOfNotes=(id:string|number)=>Http.get(`/incidents/${id}/notes`)

export const deleteIncidentNote=(data:{
  id:string|number,
  noteId:string|number,
})=>Http.delete(`/incidents/${data.id}/notes/${data.noteId}`)


export const updateincidentNoteReq=(data:IncidentNoteData)=>Http.put(`/incidents/${data.id}/notes/${data.noteId}`,{
  type:data.type,
  note:data.note,
  is_public:data.is_public
})
export const bulkResolveManual=(data:{ids:number[]})=>Http.post('/incidents/resolve',data)

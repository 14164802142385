import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'credits'

const getCredit = (params) => {
  return Http.get(`${url}${params}`)
}

const Credit = () => {
  return useMutation(getCredit)
}

export default Credit

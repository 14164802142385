/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ProgressBar} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import useIsDigitalOceanUser from '../../../../app/Hooks/useIsDigitalOceanUser'
import useIsTrial from '../../../../app/Hooks/useIsTrial'
import useMainConfig from '../../../../app/Hooks/useMainConfig'
import InfoBox from '../../../../app/modules/global/InfoBox'
import {DaysFromNowTo} from '../../../../helpers/DaysFromNowTo'
import {valueToPercent} from '../../../../helpers/ValueToPercent'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const TrialUser: FC = () => {
  const intl = useIntl()
  const {trial_end_at, options,is_free_plan} = useMainConfig()
  const isInTrial = useIsTrial()
  const isDigitalOcean = useIsDigitalOceanUser()

  const showQuantityCount = (item:any) => {
    if (item?.quantity == '-1') {
      return <strong className='fas fa-infinity'></strong>
    } else {
      return item?.quantity
    }
  }
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column flex-center bgi-no-repeat bg-primary rounded-top px-9 py-6'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold mb-3 d-flex align-items-center gap-2'>
          <KTSVG
            path='/media/icons/duotune/abstract/abs019.svg'
            className='svg-icon-1 text-white'
          />
          {isInTrial?intl.formatMessage({id: 'TRIAL.ENDS'}, {day: DaysFromNowTo(trial_end_at)}):intl.formatMessage({id:"LABELS.ACCOUNT_USAGE"})}
        </h3>
      </div>
      <div className='p-2 px-6 mt-4 '>
        <div>
          <h6 className='fs-7'>
            <FormattedMessage id='MENU.SERVERS' /> ({options?.device?.used}/
            {options?.device?.quantity})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.device?.used, options?.device?.quantity)}
          />
        </div>
        {is_free_plan&& <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='WINDOWS.SERVER' /> ({options?.windows_server?.used}/
            {showQuantityCount(options?.windows_server)})
            
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.windows_server?.used, options?.windows_server?.quantity)}
          />
        </div>}
        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='LABELS.SERVER_OPTIONS.CHECKS' /> ({options?.module?.used}/
            {showQuantityCount(options?.module)})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.module?.used, options?.module?.quantity)}
          />
        </div>
        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='PUBLIC.STATUS' /> ({options?.status_page?.used}/
            {showQuantityCount(options?.status_page)})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.status_page?.used, options?.status_page?.quantity)}
          />
        </div>

        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='MENU.NOTIFICATION_ROLES' /> ({options?.notification_roles?.used}/
            {showQuantityCount(options?.notification_roles)})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(
              options?.notification_roles?.used,
              options?.notification_roles?.quantity
            )}
          />
        </div>
        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='PAGE.TITLES.SUB_ACCOUNTS' /> ({options?.contacts?.used}/
            {showQuantityCount(options?.contacts)})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.contacts?.used, options?.contacts?.quantity)}
          />
        </div>
        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='MENU.REPORTS' /> ({options?.scheduled_email_reports?.used}/
            {showQuantityCount(options?.scheduled_email_reports)})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.scheduled_email_reports?.used, options?.scheduled_email_reports?.quantity)}
          />
        </div>
        <div className='mt-4 '>
          <h6 className='fs-7'>
            <FormattedMessage id='LABELS.SMS' /> ({options?.sms?.used}/
            {options?.sms?.quantity})
          </h6>
          <ProgressBar
            variant='primary'
            now={valueToPercent(options?.sms?.used, options?.sms?.quantity)}
          />
        </div>
      {isInTrial&&  <InfoBox text={intl.formatMessage({id: 'TRIAL.TEXT'})} />}
      </div>

      <div className='py-2 text-center border-top '>
        {
          isDigitalOcean ?
          <a href='https://cloud.digitalocean.com/add-ons' target={"_blank"} className='btn  btn-active-color-primary'>
          {intl.formatMessage({id: 'LABELS.UPGRADE_SERVICE'})}{' '}
          <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
        </a>
          :

          <Link to='/billing' className='btn  btn-active-color-primary'>
          {intl.formatMessage({id: 'LABELS.UPGRADE_SERVICE'})}{' '}
          <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
        </Link>
        }
        
      </div>
    </div>
  )
}

export {TrialUser}

import React, {FC, useEffect, useState} from 'react'
import {Col, Collapse, Row} from 'react-bootstrap'
import SingleCredit from './SingleComponents/SingleCredit'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'


const BillingCredits: FC<{creditDataHandler: any, opener?:any}> = ({creditDataHandler,opener}) => {
  const intl = useIntl()
  const [creditDataType, setCreditDataType] = useState<any>('')
  const [open, setOpen] = useState(opener ? opener: false)
  useEffect(() => {
    creditDataHandler.creditDataGetter(3, creditDataType)
  }, [])
  return (
    <div className='main-service-container step5 '>
   
      <div>
        <Row className='align-items-center'>
          <Col lg={9} xs={12}>
            <div className='tab-content '>
              {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Credits'})}
            </div>
          </Col>

          <Col lg={3} xs={12}>
            <select
              className='form-select form-select-solid text-dark text-hover-primary'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={''}
              value={creditDataType}
              onChange={(e) => {
                setCreditDataType(e.target.value)
                creditDataHandler.creditDataGetter(3, e.target.value)
              }}
            >
              <option value=''> {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.all'})}</option>
              <option value='active'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.Active'})}
              </option>
              <option value='inactive'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.inactive'})}
              </option>
            </select>
          </Col>
        </Row>

        {creditDataHandler.loadingCredit ? (
          <div className='text-center' style={{minHeight: '240px'}}>
            <div
              className='spinner-border'
              style={{width: '3rem', height: '3rem', marginTop: '80px'}}
              role='status'
            >
              <span className='sr-only'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.LOADING'})}
              </span>
            </div>
          </div>
        ) : (
          <div>
            {creditDataHandler.creditDataList &&
              creditDataHandler.creditDataList.map((itm: any, idx: any) => {
                if (idx < 3) return <SingleCredit key={itm.id} data={itm} />
              })}
            <Collapse in={open}>
              <div id='example-collapse-text'>
                {creditDataHandler.creditDataList &&
                  creditDataHandler.creditDataList.map((itm: any, idx: any) => {
                    if (idx >= 3 && idx < 6) return <SingleCredit key={itm.id} data={itm} />
                  })}
              </div>
            </Collapse>
            {creditDataHandler.creditDataList && creditDataHandler.creditDataList.length === 0 && (
              <p className='text-center pt-10'>
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
              </p>
            )}
            {/* <SingleCredit /> */}
            <div className='d-flex justify-content-between pt-10 px-2 '>
              <div
                onClick={() => setOpen(true)}
                style={{cursor: 'pointer'}}
                className='text-dark fw-bold text-hover-primary'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr082.svg' className='svg-icon-1' />
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.MORE'})}
              </div>
              <div
                onClick={() => setOpen(false)}
                style={{cursor: 'pointer'}}
                className='text-dark fw-bold text-hover-primary'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr081.svg' className='svg-icon-1' />
                {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.LESS'})}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BillingCredits

export interface IProfileDetails {
    avatar: string
    first: string
    last: string
    username: string
    company: string
    website: string
    timezone: string
    table_length: number
    currency_id: number
    phone: string
    country: string
    state: string
    city: string
    address: string
    zip: string
    mobile: string
    language: string
   
    file?: object | null
    title: string
    
    email: string
    name: string
}

export interface IContactDetails {
    first?: string
    last?: string
    title?: string
    email?: string
    username?: string
    phone?: string
    mobile?: string
    language: string
    full_access: number | boolean
    access: any
    name?: string
    avatar?: string
    file?: object | null
}

export interface IUpdateEmail {
    newEmail: string
}

export interface IUEnableTwoFA {
    pinCode: string
    qr_code?: string
}

export interface IAddApiKey {
    title: string
    expire_at: string | null
}

export interface IUpdatePassword {
    currentPassword: string
    newPassword: string
    passwordConfirmation: string
}

export interface IConnectedAccounts {
    google: boolean
    github: boolean
    stack: boolean
}

export interface IEmailPreferences {
    successfulPayments: boolean
    payouts: boolean
    freeCollections: boolean
    customerPaymentDispute: boolean
    refundAlert: boolean
    invoicePayments: boolean
    webhookAPIEndpoints: boolean
}

export interface IFeedback {
    type: string
    subject: string
    body: string
    department_id?: number
    priority?: number
}

export interface INotifications {
    notifications: {
        email: boolean
        phone: boolean
    }
    billingUpdates: {
        email: boolean
        phone: boolean
    }
    newTeamMembers: {
        email: boolean
        phone: boolean
    }
    completeProjects: {
        email: boolean
        phone: boolean
    }
    newsletters: {
        email: boolean
        phone: boolean
    }
}

export interface IAccessList {
    api: boolean
    report: boolean
    billing: boolean
    tickets: boolean
    notification_roles: boolean
}

export interface IDeactivateAccount {
    confirm: boolean
}

export const profileDetailsInitValues = {
    avatar: '/media/avatars/150-2.jpg',
    file: null
}

export const updateEmail: IUpdateEmail = {
    newEmail: '',
}

export const updatePassword: IUpdatePassword = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
    google: true,
    github: true,
    stack: false,
}

export const emailPreferences: IEmailPreferences = {
    successfulPayments: false,
    payouts: true,
    freeCollections: false,
    customerPaymentDispute: true,
    refundAlert: false,
    invoicePayments: true,
    webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
    notifications: {
        email: true,
        phone: true,
    },
    billingUpdates: {
        email: true,
        phone: true,
    },
    newTeamMembers: {
        email: true,
        phone: false,
    },
    completeProjects: {
        email: false,
        phone: true,
    },
    newsletters: {
        email: false,
        phone: false,
    },
}

export const deactivateAccount: IDeactivateAccount = {
    confirm: false,
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Dropdown1, useThemeMode} from '../../../../../_metronic/partials'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  percentValue:any
  labelValue:any
}

const CurrentServiceChart: React.FC<Props> = ({className, chartColor, chartHeight, percentValue,labelValue}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,percentValue,labelValue))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string,percentValue:any,labelValue:any): ApexOptions => {
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-'  + 'secondary')
  const labelColor = getCSSVariableValue('--kt-gray-700')

  return {
    series: [percentValue],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '70%',
        },
        dataLabels: {
          name: {
            show: true,
            fontWeight: '300',
          },
          value: {
            color: labelColor,
            fontSize: '18px',
            fontWeight: '700',
            offsetY: 15,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: [labelValue],
  }
}

export {CurrentServiceChart}
